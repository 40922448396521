import React, { Fragment, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ThemeCustomize from "../Layout/ThemeCustomizer";
import Footer from "./Footer";
import CustomizerContext from "../_helper/Customizer";
import AnimationThemeContext from "../_helper/AnimationTheme";
import ConfigDB from "../Config/ThemeConfig";
import NavBar from "./TopNavBar/NavBar";
import WaiterNavBar from "./TopNavBar/WaiterNarBar";
import UserContext from "../_helper/User";
import PopupComponent from "./TopNavBar/Showpopup";
import TableContext from "../_helper/TableHolder";
import { useEffect } from "react";
import LocationContext from "../_helper/Location";
import { useSelector } from "react-redux";
import { selectItemsInCart } from "../cart/cartSlice";

const AppLayout = ({ children, classNames, ...rest }) => {
  const cartItems = useSelector(selectItemsInCart);
  const [matchtab, setTableid] = useState([]);
  const { layout } = useContext(CustomizerContext);
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const { userData } = useContext(UserContext);
  const { waiterintable } = useContext(TableContext);
  const { waiterin } = useContext(LocationContext);
  const [simulateNavigation, setSimulateNavigation] = useState(false);
  let { orgId, locationId, tableId } = useParams();
  const history = useNavigate();
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;
  // Fetch the current table from local storage
  const currentTable = localStorage.getItem("waiterintable_id");
  console.log(cartItems);

  useEffect(() => {
    // Extract tableId from the first item in cartItems (you can adjust as needed)
    const filteredCarts =
      cartItems.length > 0 ? cartItems[0].item.tableId : null;
    setTableid(filteredCarts);
    console.log(filteredCarts);
  }, [cartItems]);
  console.log(waiterintable.id);

  const isSamplePage =
    location.pathname === `/pages/sample/${orgId}/${locationId}/${tableId}`;
  const islogin = location.pathname === "/waiter-login";

  // Check if the current table matches the waiter's assigned table
  const isPopupPage =
    location.pathname.includes("/sample-page/interface-one/") &&
    userData &&
    userData.servicemanSaBpartnerId &&
    userData.saOrgId;

  // Check if the current table matches the waiter's assigned table
  const isPopupongoing =
    location.pathname.includes("/sample-page/interface-one/") &&
    userData &&
    userData.servicemanSaBpartnerId &&
    userData.saOrgId;

  // Check if the current table matches the waiter's assigned table
  const shouldRenderPopup = waiterintable.id !== matchtab;

  console.log(matchtab);

  return (
    <Fragment>
      {/* {isSamplePage ? null : <TopNavBar />} */}

      {isPopupPage && shouldRenderPopup && (
        <PopupComponent matchtab={matchtab} />
      )}

      {/* Conditionally render WaiterNavBar if servicemanSaBpartnerId and saOrgId exist */}
      {isSamplePage || islogin ? null : userData &&
        userData.servicemanSaBpartnerId &&
        userData.saOrgId ? (
        <WaiterNavBar />
      ) : (
        <NavBar />
      )}
      {/* Other components */}
      <div className="page-body">
        <TransitionGroup {...rest}>
          <CSSTransition
            key={location.key}
            timeout={100}
            classNames={animationTheme}
            unmountOnExit
          >
            <div>
              <div>
                <Outlet />
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
      {/* Other components */}
    </Fragment>
  );
};
export default AppLayout;
