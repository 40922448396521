// import React, { useState } from "react";
// import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
// import NavAuth from "./Nav";
// import LoginTab from "./Tabs/LoginTab";
// import AuthTab from "./Tabs/AuthTab";
// import { ToastContainer } from "react-toastify";

// const Logins = () => {
//   const [selected, setSelected] = useState("simpleLogin");

//   const callbackNav = (select) => {
//     setSelected(select);
//   };

//   return (
//     <Container fluid={true} className="p-0 login-page">
//       <Row>
//         <Col xs="12">
//           <div className="login-card">
//             <div className="login-main login-tab">
//               <NavAuth callbackNav={callbackNav} selected={selected} />
//               <TabContent activeTab={selected} className="content-login">
//                 <TabPane
//                   className="fade show"
//                   tabId={selected === "simpleLogin" ? "simpleLogin" : "jwt"}
//                 >
//                   <LoginTab selected={selected} />
//                 </TabPane>
//                 <TabPane className="fade show" tabId="auth0">
//                   <AuthTab />
//                 </TabPane>
//               </TabContent>
//             </div>
//           </div>
//         </Col>
//       </Row>
//       <ToastContainer />
//     </Container>
//   );
// };

// export default Logins;

import React, { Fragment } from "react";
import { Col, Container, Input, Row } from "reactstrap"; // Assuming you have Bootstrap installed and properly configured
// import Footer from "../../../Layout/Footer";
// import { Text } from "../../Constant";
// import TopNavBar from "../../../Layout/TopNavBar";
import LoginTab from "./Tabs/LoginTab";
// import LoginTab from "../LoginTab";
// import LoginForm from "../../../Layout/Waiter Login/Login";

const Logins = () => {
  return (
    <Fragment>
      <Container fluid={true} className="p-0">
        {/* <TopNavBar /> */}
        <Row>
          <Col
            xl="7"
            className="b-center bg-size"
            style={{
              backgroundImage: `url(${require("../assets/images/login/4043422.jpg")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          >
            {/* <Image
              attrImage={{
                className: "bg-img-cover bg-center d-none",
                src: `${require("../../assets/images/login/2.jpg")}`,
                alt: "looginpage",
              }}
            /> */}
          </Col>
          <Col>
            <LoginTab />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Logins;
