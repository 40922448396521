import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { useKeenSlider } from "keen-slider/react";
import { ArrowLeft, ArrowRight } from "react-feather";
import "./menulist.css";
import FoodItem from "./FoodItem";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
  defaultPath,
} from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Left } from "../../Constant";
import UserContext from "../../_helper/User";
import LocationContext from "../../_helper/Location";
import TableContext from "../../_helper/TableHolder";
import { Btn } from "../../AbstractElements";

const FoodList = ({ categoryList, saCategoryId }) => {
  const [slideLeft, setSlideLeft] = React.useState(0);
  const [hideButtonLeft, setHideButtonLeft] = React.useState(true);
  const [hideButtonRight, setHideButtonRight] = React.useState(false);
  const [sliderWidth, setSliderWidth] = React.useState(0);

  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);

  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  React.useEffect(() => {
    setSliderWidth(
      document.getElementById("hscroll").scrollWidth -
        document.getElementById("hscroll").offsetWidth
    );
  }, []);

  const btnItem = {
    colorClass: "transparent",
  };

  //on arrow click
  const moveRight = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft += 200));
  };

  const moveLeft = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft -= 200));
  };

  const onHScroll = () => {
    const el = document.getElementById(`hscroll`).scrollLeft;
    if (el > 0) {
      setHideButtonLeft(false);
    } else {
      setHideButtonLeft(true);
    }
    if (el < sliderWidth) {
      setHideButtonRight(false);
    } else {
      setHideButtonRight(true);
    }
  };
  if ((!categoryList, saCategoryId)) {
    return "whats_on_your_mind";
  }
  console.log(categoryList);

  return (
    <div className={`w-full pt-2`}>
      <div className="d-flex  items-center justify-between">
        {/* <hr style={{ backgroundColor: "black" }} /> */}
        <div
          className="flex-container"
          id={`hscroll`}
          onScroll={() => onHScroll()}
        >
          {categoryList.map((item, i) => (
            <div key={i}>
              {/* <img src={backendURL + item.filePath} alt="images" style={{ width: "18rem" }} /> */}
              {/* <div className="keen-slider">
                {item?.map((food) => (
                  <FoodItem food={food} key={food.id} />
                ))}
              </div> */}
              <Link
                to={`/interface-one/restaurants/${orgId}/${locationId}/${tableId}/${item?.saCategoryId}`}
                className="hover:scale-95 transition ease-in-out duration-300 relative z-10 font-bold text-warning"
                // key={i}
                // onClick={handleShowPopup}
              >
                {" "}
                <div
                  className="btn-showcase p-2"

                  // style={{ minWidth: "175px", padding: "5px" }}
                >
                  <Fragment key={i}>
                    <button
                      className={`btn bg-light hover-bg-orange-500 text-orange-400 font-bold rounded-md border border-warning-subtle m-1 p-2 text-inline `}
                      style={{ minWidth: "max-content" }}
                    >
                      {item?.categoryName}
                    </button>
                  </Fragment>
                </div>
              </Link>
            </div>
          ))}
        </div>

        {/* <div className="flex gap-2 items-center">
          <button
            // disabled={currentSlide === 0}
            onClick={moveLeft}
            className="bg-gray-100 p-2 rounded-full disabled:text-gray-300"
          >
            <ArrowLeft className="w-4 h-4" />{" "}
          </button>
          <button
            onClick={moveRight}
            className="bg-gray-100 p-2 rounded-full disabled:text-gray-300"
          >
            <ArrowRight className="w-4 h-4" />{" "}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default FoodList;
