import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { backendURL } from "../Config/Config";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
  GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
} from "../utils/constants";
// import { selectAddress } from "../features/address/addressSlice";

const useRestaurants = ({ categoryId, orgId, locationId, tableId }) => {
  // const { address } = useSelector(selectAddress);
  const [banners, setBanners] = useState([]);
  const [foods, setFoods] = useState([]);

  const [wtables, setwTables] = useState([]);
  const [topRestaurants, setTopRestaurants] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getRestaurants = async () => {
    setIsLoading(true);
    setError(null);

    try {
      setIsLoading(true);
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      const { data } = await reqInstance.post(GET_ALL_ACTIVE_CATEGORY_FOR_PO);

      if (data?.data) {
        setBanners(
          data?.data?.cards.filter(
            (items) => items?.card?.card?.id === "topical_banner"
          )[0]
        );

        // setFoods(
        //   data?.data?.cards.filter(
        //     (items) => items?.card?.card?.id === "whats_on_your_mind"
        //   )[0]
        // );
        setFoods(data);
        setwTables(
          data?.data?.cards.filter(
            (items) => items?.card?.card?.id === "whats_on_your_mind"
          )[0]
        );
        setTopRestaurants(
          data?.data?.cards.filter(
            (items) => items?.card?.card?.id === "top_brands_for_you"
          )[0]
        );

        setRestaurants(
          data?.data?.cards.filter(
            (items) => items?.card?.card?.id === "restaurant_grid_listing"
          )[0]?.card?.card?.gridElements?.infoWithStyle?.restaurants
        );
      }
    } catch (err) {
      console.log(err.response);
      setError(err.response);
    } finally {
      setIsLoading(false);
    }
  };
  //  useEffect(() => {
  //    const fetchData = async () => {
  //      const requestData = {
  //        orgId: 41,
  //        locationId: 544,
  //        tableId: 694,
  //        categoryId:1068,
  //      };

  //      try {
  //        const reqInstance = axios.create({
  //          baseURL: backendURL,
  //        });

  //        const response_data = await reqInstance.post(
  //          GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
  //          requestData
  //        );

  //        if (response_data) {
  //          const result = response_data.data;

  //          if (!result.hasError) {
  //            //
  //            console.log(result);
  //          } else {
  //            console.error("Error in API response:", result.errorMessage);
  //          }
  //        }
  //      } catch (error) {
  //        console.error("Error fetching data:", error);
  //      }
  //    };

  //    fetchData();
  //  }, []);

  // useEffect(() => {
  //   getRestaurants();
  // }, [address.city]);

  return {
    wtables,
    banners,
    foods,
    topRestaurants,
    restaurants,
    isLoading,
    error,
    triggerGetRestaurants: () => {
      return getRestaurants();
    },
  };
};

export default useRestaurants;
