import React from "react";

import { Col, Row, CardFooter, Card, Container } from "reactstrap";

import { Fragment } from "react";

import CourseBox from "../../Components/Pages/PageLayout/ToggleFooter";

const CartFooter = ({ totalItems, tableId }) => {
  const quantity = 1;
  const isFooterVisible = totalItems > 0;

  return (
    <Fragment>
      <div className={`   ${isFooterVisible ? "" : "d-none"} `}>
        <Col xxl="3" xl="5" md="5" className="box-col-6 fixed-bottom m-t-40 ">
          <Row className="mx-5">
            <Col sm="12">
              <CourseBox mainClass="widget-course" />
            </Col>
          </Row>
        </Col>
      </div>
    </Fragment>
  );
};
export default CartFooter;
