// import { Link } from "react-router-dom";

// //import { StarIcon } from '@heroicons/react/24/solid';
// import {
//   CDN_URL,
//   GET_ALL_ACTIVE_CATEGORY_FOR_PO,
// } from "../../../src/utils/constants";
// import { backendURL } from "../../Config/Config";

// const RestaurantCard = ({ restaurant }) => {
//   const { categoryList } = restaurant;

//   return (
//     <>
//       <div className="overlay-container">
//         <img
//           src={categoryList.filePath}
//           alt="restaurant"
//           className="relative w-full min-h-[180px] overflow-hidden aspect-video object-cover block rounded-md"
//         />
//         <div className="overlay w-full rounded-md p-2 px-3 ">
//           <p className="text-xl font-bold flex gap-2 flex-wrap">
//             {categoryList?.aggregatedDi.scountInfoV3?.header
//               ? categoryList.aggregatedDiscountInfoV3.header
//               : ""}{" "}
//             {categoryList?.aggregatedDiscountInfoV3?.subHeader
//               ? categoryList.aggregatedDiscountInfoV3.subHeader
//               : ""}
//           </p>
//         </div>
//       </div>

//       <h2 className="text-lg font-semibold mt-2 text-zinc-800">
//         {categoryList.categoryName}
//       </h2>
//       <div className="flex items-center gap-2">
//         {/* <StarIcon className='w-6 h-6 text-orange-400' />{' '} */}
//         <p className="font-semibold text-gray-700 text-sm">
//           {categoryList.noOfItem}
//         </p>
//       </div>

//       <p className="truncate  text-zinc-600">
//         {categoryList?.cuisines?.map((c, i) => (
//           <span key={i}>
//             {c}
//             {i === categoryList.cuisines.length - 1 ? "" : ", "}
//           </span>
//         ))}
//       </p>

//       <p className="text-zinc-600">{"info.locality"}</p>
//     </>
//   );
// };

// export default RestaurantCard;

//  HOC for Top Rated Restaurants
import React from "react";
import { Back } from "../../Constant";
import { backendURL } from "../../Config/Config";
import { GET_ALL_ACTIVE_CATEGORY_FOR_PO } from "../../utils/constants";
import { useState } from "react";
import defaultImage from "../../assets/images/default/def.png";

const RestaurantCard = ({ categoryList }) => {
  const imagePath = categoryList.filePath
    ? `${backendURL}${categoryList.filePath}`
    : defaultImage;

  return (
    <>
      <div className="overlay-container">
        <img
          src={imagePath}
          alt="Category"
          // className="relative w-full min-h-[180px] overflow-hidden aspect-video object-cover block rounded-md"
          className="relative h-full basis-9\/12 overflow-hidden aspect-square rounded-md"
        />
        <div className="overlay w-full rounded-md p-2 px-3">
          <p className="text-xl font-bold flex gap-2 flex-wrap">
            {categoryList.categoryName}
          </p>
        </div>
      </div>

      <h2 className="text-lg font-semibold mt-2 text-zinc-800">
        {categoryList.description}
      </h2>
      <div className="flex items-center gap-2">
        {/* Display noOfItem in the button */}
        <button className="button-1" role="button">
          {categoryList.noOfItem} Items
        </button>
      </div>

      <p className="truncate text-zinc-600">
        {/* Display cuisines if available */}
        {categoryList.cuisines?.length > 0 &&
          categoryList.cuisines.map((c, i) => (
            <span key={i}>
              {c}
              {i === categoryList.cuisines.length - 1 ? "" : ", "}
            </span>
          ))}
      </p>

      {/* Assuming 'info' is available in your data structure */}
      <p className="text-zinc-600">{categoryList.info?.locality}</p>
    </>
  );
};

export default RestaurantCard;

export const withTopRatedLabel = (RestaurantCard) => {
  return (props) => {
    return (
      <div className="relative">
        <p className="absolute z-10 -top-2 -left-2 rounded-md p-2 px-4 bg-zinc-900 text-white text-xs">
          Top Rated
        </p>
        <RestaurantCard {...props} />
      </div>
    );
  };
};
