import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./index";
import { backendURL } from "../../Config/Config";
import { GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS } from "../../utils/constants";
import axios from "axios";
import { useContext } from "react";
import UserContext from "../User";
import LocationContext from "../Location";

const TableHolderProvider = (props) => {
  const [tableData, setTableData] = useState({});
  const [waiterintable, setWaiterInTable] = useState([]);

  const userData = useContext(UserContext);
  // const userData = useContext(UserContext);
  // const waiterin = useContext(LocationContext);
  let orgId = userData.userData.saOrgId;
  let locationId = userData.userData.locationId;

  console.log(userData);

  const addTableData = (table_data) => {
    console.log("Data Set");
    setTableData(table_data);
  };

  // useEffect to fetch data if addTableData did not fetch any data
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Check if tableData is already populated
  //       if (!tableData || Object.keys(tableData).length === 0) {
  //         const requestData = {
  //           orgId: orgId,
  //           locationId: locationId,
  //         };

  //         const reqInstance = axios.create({
  //           baseURL: backendURL,
  //         });

  //         const tableResponse = await reqInstance.post(
  //           GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
  //           requestData
  //         );

  //         if (tableResponse) {
  //           const tableResult = tableResponse.data;

  //           // Only update state if there is new data
  //           if (!tableResult.hasError) {
  //             setTableData(tableResult.tableDetail);
  //             console.log(tableResult);
  //           } else {
  //             console.error(
  //               "Error in category API response:",
  //               tableResult.errorMessage
  //             );
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [orgId, locationId]); // Include orgId and locationId as dependencies

  const WaiterInTable = (location_in) => {
    // console.log("Waiter Location Data Set");
    setWaiterInTable(location_in);
  };

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("tabledata", JSON.stringify(tableData));
  }, [tableData]);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("fetchtable", JSON.stringify(waiterintable));
  }, [waiterintable]);

  useEffect(() => {
    // Update local storage with the current value of waiterintable for each table
    Object.entries(waiterintable).forEach(([tableId, value]) => {
      localStorage.setItem(`waiterintable_${tableId}`, JSON.stringify(value));
    });
  }, [waiterintable]);

  // useEffect to handle local storage
  useEffect(() => {
    // Get the current stored value from local storage
    const storedTable = localStorage.getItem("waiterintable_table");
    const storedTableid = localStorage.getItem("waiterintable_tableid");
    const storedTableposid = localStorage.getItem("waiterintable_tableposid");
    // Check if the stored value is different from the current state
    if (storedTable !== waiterintable.table) {
      // Update the state with the stored value
      setWaiterInTable((prev) => ({
        ...prev,
        table: storedTable,
      }));
    }
    if (storedTableid !== waiterintable.id) {
      // Update the state with the stored value
      setWaiterInTable((prev) => ({
        ...prev,

        id: storedTableid,
      }));
    }

    if (storedTableposid !== waiterintable.posMasterIdTable) {
      // Update the state with the stored value
      setWaiterInTable((prev) => ({
        ...prev,

        posid: storedTableposid,
      }));
    }
  }, []); // Empty dependency array means this effect runs once after the component mounts

  // useEffect to update local storage when waiterintable.table changes
  useEffect(() => {
    // Update local storage with the current value of waiterintable.table
    // Update local storage with the current value of waiterintable.table
    // Update local storage with the current value of waiterintable.table
    localStorage.setItem("waiterintable_table", waiterintable.table);
  }, [waiterintable.table]);
  useEffect(() => {
    // Update local storage with the current value of waiterintable.table
    localStorage.setItem("waiterintable_tableid", waiterintable.id);
  }, [waiterintable.id]);
  useEffect(() => {
    localStorage.setItem("waiterintable_tableposid", waiterintable.posid);
  }, [waiterintable.posid]);

  console.log(tableData);
  return (
    <Context.Provider
      value={{
        ...props,
        tableData,
        addTableData: addTableData,
        waiterintable,
        WaiterInTable: WaiterInTable,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default TableHolderProvider;
