// waiterLocationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
  loading: false,
  error: null,
};

const waiterLocationSlice = createSlice({
  name: "waiterLocation",
  initialState,
  reducers: {
    fetchLocationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLocationsSuccess(state, action) {
      state.loading = false;
      state.locations = action.payload;
    },
    fetchLocationsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationsFailure,
} = waiterLocationSlice.actions;

export default waiterLocationSlice.reducer;
