import React from "react";
import { CardBody, Card } from "reactstrap";
// import { CarouselsData } from "../../Common/Data/Bonus-ui";
// import Image from "../../../CommonElements/Media";
import Slider from "react-slick";
import { Image } from "../../AbstractElements";
// import HeaderCard from "";

export const SlickSlider = ({
  slides,
  rtl,
  title,
  lazyLoad,
  autoplay,
  focusOnSelect,
}) => {
  var settings = {
    // dots: true,
    infinite: true,
    autoplay: autoplay,
    speed: 400,
    arrows: false,
    slidesToShow: slides,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoPlay: true,
    centerPadding: "10px",
    centerMode: false,
    rtl: rtl,
    focusOnSelect: focusOnSelect,
    lazyLoad: lazyLoad,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const CarouselsData = [
    {
      id: 1,
      img: require("../../../src/assets/images/banner/6034595.png"),
    },
    {
      id: 2,
      img: require("../../../src/assets/images/banner/foodb1.png"),
    },
    {
      id: 3,
      img: require("../../../src/assets/images/banner/foodb2.png"),
    },
    {
      id: 4,
      img: require("../../../src/assets/images/banner/6034595.png"),
    },
    {
      id: 5,
      img: require("../../../src/assets/images/banner/foodb1.png"),
    },
    {
      id: 6,
      img: require("../../../src/assets/images/banner/foodb2.png"),
    },
    {
      id: 7,
      img: require("../../../src/assets/images/banner/6034595.png"),
    },
    {
      id: 8,
      img: require("../../../src/assets/images/banner/foodb1.png"),
    },
  ];

  return (
    <div className="container-max pb-4 px-2">
      <div className="my-4 mt-8 font-bold text-2xl text-zinc-700">
        Best Offers For You
      </div>
      <div>
        {/* <HeaderCard title={title} /> */}
        <CardBody>
          <Slider {...settings}>
            {CarouselsData.map((item) => (
              <div className="item px-2 w-full basis-8/12" key={item.id}>
                <Image
                  attrImage={{
                    src: `${item.img}`,
                    alt: "",
                    className:
                      "w-full h-full aspect-video object-fill rounded-md",
                  }}
                />
              </div>
            ))}
          </Slider>
        </CardBody>
      </div>
    </div>
  );
};

export default SlickSlider;
