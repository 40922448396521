import React, { Fragment, useEffect, useState } from "react";
import { Modal, Col, InputGroup, Input, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Btn, H4, H6, P } from "../../AbstractElements";
import { X } from "react-feather";
import CustomizerContext from "../../_helper/Customizer";
import ProductContext from "../../_helper/Ecommerce/Product";
import { useContext } from "react";
import { backendURL } from "../../Config/Config";
import defaultImage from "../../assets/images/default/def.png";
import {
  addToCart,
  decreaseItemQuantity,
  increaseItemQuantity,
  selectItemsInCart,
} from "../../cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { defaultPath } from "../../utils/constants";

const ProductModal = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(props.value);
  const {
    handleAddToCart,
    // decreaseItemQuantity,
    // increaseItemQuantity,
    removeItem,
  } = props;
  const cartItems = useSelector(selectItemsInCart);
  const decreaseQuantity = (id) => dispatch(decreaseItemQuantity({ id }));
  const increaseQuantity = (id) => dispatch(increaseItemQuantity({ id }));
  const currentTime = new Date();
  const itemAvailableFrom = new Date(props.itemAvailableFrom);
  const itemAvailableTo = new Date(props.itemAvailableTo);

  const { layoutURL } = useContext(CustomizerContext);
  const { productItem, symbol } = useContext(ProductContext);

  const [quantity, setQuantity] = useState(1);
  const [singleProduct, setSingleProduct] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    console.log(cartItems);
    console.log(props.dataid);
    const product = cartItems.find((item) => item.item.id === props.dataid);
    if (product === undefined) {
      setSingleProduct({
        quantity: 0,
      });
    } else {
      setSingleProduct(product);
    }
  }, [cartItems]);

  console.log(props);

  // useEffect(() => {
  //   setOpen(props.value);
  //   productItem.forEach((product) => {
  //     if (product.id === props.dataid) {
  //       setSingleProduct(product);
  //     }
  //   });
  // }, [props.value, productItem, props.dataid]);

  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1);
    }
  };

  const minusQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const shouldDisable = () => {
    // Check if the item is not available or the current time is outside the available range
    return (
      !props.isavailable ||
      currentTime < itemAvailableFrom ||
      currentTime > itemAvailableTo
    );
  };

  console.log(props.itemAvailableFrom);

  const defimagePath = props.image
    ? `${backendURL}${props.image}`
    : defaultImage;

  return (
    <Fragment>
      <Modal
        className="modal-dialog modal-lg modal-dialog-centered product-modal"
        isOpen={props.value}
      >
        <div className="modal-body">
          <div className="modal-header">
            <div className="product-box  row py-1">
              <Col lg="12" className="align-item-center">
                <img
                  src={defimagePath}
                  className="img-fluid mx-auto"
                  alt="items"
                />
              </Col>
              <Col lg="12" className="product-details text-start">
                <H4>{props.name}</H4>
                <div className="product-price text-success fs-6">
                  {props.currency}&nbsp;{props.price.toFixed(2)}
                </div>
                <div className="product-view">
                  <H6 attrH6={{ className: "f-w-600" }}>Nourishment</H6>
                  <P attrPara={{ className: "mb-0" }}>
                    {props.description || "No available cuisine"}
                  </P>
                </div>
                <div
                  className={`product-qnty py-1 ${
                    shouldDisable()
                      ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                      : ""
                  }`}
                >
                  <H6 attrH6={{ className: "f-w-600" }}>Quantity</H6>
                  <div>
                    <div className="flex justify-between items-center mt-2">
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            handleAddToCart &&
                            decreaseQuantity(singleProduct.item.id)
                          }
                          disabled={
                            singleProduct.quantity === 1 ||
                            singleProduct.quantity === 0
                          }
                          className={`bg-orange-500 disabled:bg-orange-500/50 disabled:cursor-not-allowed text-white font-bold w-8 h-8 rounded-md ${
                            shouldDisable()
                              ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                              : ""
                          }`}
                        >
                          -
                        </button>
                        <p className="font-bold w-8 h-8 flex justify-center items-center">
                          {singleProduct.quantity}
                        </p>
                        <button
                          onClick={() => handleAddToCart()}
                          className={`bg-orange-500 text-white font-bold w-8 h-8 rounded-md ${
                            shouldDisable()
                              ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                              : ""
                          }`}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  {shouldDisable() && (
                    <P
                      attrPara={{ className: "text-danger mb-2 z-index-1" }}
                    >{`This item is not available at the moment. Available from ${props.itemAvailableFrom} to ${props.itemAvailableTo}`}</P>
                  )}
                  <div style={{ display: "flex" }}>
                    {/* <div style={{ flex: 0.3, padding: 1 }}>
                      <Button
                        color="gray"
                        className="m-r-10 m-t-10 cart-btn hover:bg-orange-500 hover:text-white"
                        onClick={handleAddToCart}
                      >
                        <i className="fa fa-shopping-basket me-1"></i>
                        Add
                      </Button>
                    </div> */}
                    <div style={{ flex: 1 }}>
                      <Link
                        to={`/interface-one/restaurants/${defaultPath.orgId}/${defaultPath.locationId}/${defaultPath.tableId}/cart`}
                      >
                        <Button
                          color="gray"
                          className={`m-r-10 m-t-10 cart-btn btn-warning ${
                            shouldDisable()
                              ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                              : ""
                          }`}
                        >
                          <i className="fa fa-shopping-cart me-1"></i>
                          View Cart
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>{" "}
              </Col>
            </div>

            <Btn
              attrBtn={{
                color: "Black",
                className: "btn-close p-4",
                onClick: props.setOpenModal,
                type: "button",
                databsdismiss: "modal",
                arialabel: "Close",
              }}
            >
              <X />
            </Btn>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductModal;
