import React from "react";
import { Card, CardBody } from "reactstrap";
// import { H4 } from '../../AbstractElements';
import SvgIcon from "../../../Common/Component/SvgIcon";
import { H1, H4 } from "../../../../AbstractElements";
// import SvgIcon from '../Component/SvgIcon';

const Widgets2 = ({ data }) => {
  return (
    <Card className="widget-1 bg-secondary ">
      <div className=" widget-content card pt-2 mb-1">
        <div className={`widget-round ${data.color} `}>
          <div className="bg-round">
            <SvgIcon className="" iconId={`${data.icon}`} />
            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
          </div>
        </div>
        {/* <div>
          <h3 className="text-danger d-flex align-items-center">
            <span className="table-heading text-xs">Id: </span>&nbsp;{" "}
            {data.searchKeytable}
          </h3>
        </div> */}
        <div>
          <h3 className="fs-6 text-danger d-flex align-items-center">
            <span className="table-heading">Table: </span>&nbsp; {data.table}
          </h3>
        </div>
      </div>
      <div className="p-l-5">
        {" "}
        <h3 className="fs-5 text-black bold d-flex align-items-center">
          {" "}
          <span className="table-heading ">Sub Total : </span> {data.subtotal}
        </h3>
      </div>
      <div className="fs-6 text-black d-flex p align-item-center justify-content-center p-l-10 p-r-10 bg-danger text-white">
        <div className="fs-6 text text-white-50 ">{data.searchKeytable}</div>{" "}
        &nbsp;&nbsp;&nbsp;
        {data.title}
      </div>
    </Card>
  );
};

export default Widgets2;
