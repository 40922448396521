import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./index";

const PosMasterProvider = (props) => {
  const [posData, setposData] = useState({});

  const addposData = (pos_data) => {
    console.log("pos Data Set");
    setposData(pos_data);
  };
  console.log(posData);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("posmasterData");
    if (storedData) {
      setposData(JSON.parse(storedData));
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("posmasterData", JSON.stringify(posData));
  }, [posData]);
  console.log(posData);
  return (
    <Context.Provider
      value={{
        ...props,
        posData,
        addposData: addposData,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default PosMasterProvider;
