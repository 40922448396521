import { useState } from "react";
// import WaiterTable from "../../../../Layout/WaiterLayouts/WaiterTable";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_POS_LOCATION_FOR_PO,
  defaultPath,
} from "../../../../utils/constants";
import { useEffect } from "react";
import Footer from "../../../../Layout/Footer";
import useRestaurants from "../../../../Hooks/useRestaurants";
import { Breadcrumb, Button, Col, Container, Row } from "reactstrap";
import { Fragment } from "react";
import Widgets1 from "./Widgets1";
import Widgets2 from "./Widgets2";
import Widgets3 from "./Widgets3";
// import { useNavigate } from "@reach/router";
import ProductModal from "./ProductModal";
import { Btn } from "../../../../AbstractElements";
import WaiterLocationCard from "./WaiterLocationCard";
import { useNavigate, useParams, useRouteLoaderData } from "react-router";
import axios from "axios";
import { backendURL } from "../../../../Config/Config";
import { useDispatch, useSelector } from "react-redux";
// import { LocationContext } from "../../../../_helper/Location";
import {
  fetchLocationsFailure,
  fetchLocationsStart,
  fetchLocationsSuccess,
} from "../../../../cart/WaiterSlice";
import { useContext } from "react";
import LocationContext from "../../../../_helper/Location";
import UserContext from "../../../../_helper/User";
// import { Button } from "react-bootstrap";

const small = [
  {
    title: "Restaurant",
    color: "success",
    total: 3_908,
    gros: 80,
    prefix: "$",
    icon: "cart",
  },
];
function WaiterLocation() {
  const navigate = useNavigate();
  // const { orgId, locationId, tableId } = defaultPath;
  const [location, setLocation] = useState([]);

  const dispatch = useDispatch();
  const { addWaiterLocationData, WaiterInLocation } =
    useContext(LocationContext);
  // const { } = useContext(LocationContext);
  const { locations, loading, error } = useSelector(
    (state) => state.waiterLocation
  );
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  console.log(userData.userData.saOrgId);
  console.log(waiterin.waiterin.id);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch(fetchLocationsStart());

  //     try {
  //       const requestData = {
  //         orgId: routeOrgId || orgId,
  //       };

  //       const reqInstance = axios.create({
  //         baseURL: backendURL,
  //       });s

  //       const locationResponse = await reqInstance.post(
  //         GET_ALL_ACTIVE_POS_LOCATION_FOR_PO,
  //         requestData
  //       );

  //       if (locationResponse) {
  //         const locationResult = locationResponse.data;

  //         if (!locationResult.hasError) {
  //           dispatch(fetchLocationsSuccess(locationResult.location));
  //         } else {
  //           dispatch(fetchLocationsFailure(locationResult.errorMessage));
  //         }
  //       }
  //     } catch (error) {
  //       dispatch(fetchLocationsFailure(error.message));
  //     }
  //   };

  //   fetchData();
  // }, [orgId, locationId, dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        orgId: userData.userData.saOrgId,
        // locationId: routeLocationId || locationId,
      };

      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        const locationResponse = await reqInstance.post(
          GET_ALL_ACTIVE_POS_LOCATION_FOR_PO,
          requestData
        );
        // console.log(locationResponse);

        if (locationResponse) {
          const locationResult = locationResponse.data;
          addWaiterLocationData(locationResult);
          if (!locationResult.hasError) {
            setLocation(locationResult.location);

            const Locations = locationResult.location
              .map((locationItem) => {
                const { saLocationId, searchKey, name, description } =
                  locationItem;

                let locationData;

                locationData = {
                  id: saLocationId,
                  searchKey: searchKey,
                  name: name,
                  description: description,
                };

                // console.log(locationData);
                return locationData;
              })
              .filter(Boolean);

            setLocation(Locations);
          } else {
            console.error(
              "Error in category API response:",
              locationResult.errorMessage
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userData.userData.saOrgId]);

  const handleLocationClick = (item) => {
    // Send the clicked item to the provider
    WaiterInLocation(item);

    // Navigate after the context is updated
    navigate(`/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`);
  };
  return (
    <Fragment>
      {/* <Breadcrumb mainTitle="Asset" parent="Asset" title="Asset List" /> */}
      <Container fluid={true} className="general-widget">
        <div className="bg-white relative py-8">
          {" "}
          <Row>
            {" "}
            {location.map((item, i) => (
              <Col
                lg="3"
                md="6"
                sm="12"
                key={i}
                className="box-col-4 pointer col-12 "
                color="success"
                data-toggle="modal"
                onClick={() => {
                  // Send the clicked item to the provider
                  // WaiterInLocation(item);
                  // navigate(
                  //   `/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`
                  // );
                  handleLocationClick(item);
                }}
                cursor="pointer"
              >
                <WaiterLocationCard
                  data={{
                    item: item,
                    locationId: item.id,
                    name: item.name,
                    description: item.description,
                    search: item.searchKey,
                    icon: "cart",
                  }}
                />
              </Col>
            ))}{" "}
          </Row>
          <Footer />
        </div>
      </Container>
    </Fragment>
  );
}

export default WaiterLocation;
