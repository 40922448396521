import { createSlice } from "@reduxjs/toolkit";

const localDataSlice = createSlice({
  name: "localData",
  initialState: {
    cartHistory: JSON.parse(localStorage.getItem("cartHistory")) || [],
  },
  reducers: {
    storeCartHistory: (state, action) => {
      const { id } = action.payload;

      // Find the item in the current items array
      const itemToAddToHistory = state.cartHistory.find(
        (cartItem) => cartItem?.item?.id === id
      );

      // Add the item to the cart history array
      if (itemToAddToHistory) {
        state.cartHistory = [...state.cartHistory, itemToAddToHistory];
        // localStorage.setItem("cartHistory", JSON.stringify(state.cartHistory));
      }
    },
  },
});

export const { storeCartHistory } = localDataSlice.actions;

export const selectCartHistory = (state) => state.localData.cartHistory;

export default localDataSlice.reducer;
