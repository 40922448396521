import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import SvgIcon from "../../Common/Component/SvgIcon";
import { Link } from "react-router-dom";
import UserContext from "../../../_helper/User";
import LocationContext from "../../../_helper/Location";
import TableContext from "../../../_helper/TableHolder";
import { useContext } from "react";
import { Loader, RefreshCcw } from "react-feather";

const Reload = () => {
  const WidgetsData1WidgetsPage = {
    title: "Purchase",
    gros: 50,
    total: 10_000,
    color: "warning",
    icon: "profile-check",
  };

  const handleReloadClick = () => {
    // Add logic here to perform any actions before reloading the page
    window.location.reload();
  };



  return (
    <Row className={`widget-1 col-1 `}>
      <Col xs={{ size: 10, offset: 2 }} className="position-relative">
        <div className="widget-content" onClick={handleReloadClick}>
          <div className={`widget-round ${WidgetsData1WidgetsPage.color}`}>
            <div className="bg-round">
              <i
                style={{ width: "24px", height: "24px" }}
                className="svg-fill "
                // iconId={`${WidgetsData1WidgetsPage.icon}`}
              >
                <RefreshCcw />
              </i>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    // </Link>
  );
};

export default Reload;
