import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AlignJustify,
  ArrowLeft,
  ArrowLeftCircle,
  ChevronLeft,
  LogIn,
  MapPin,
  ShoppingBag,
  ShoppingCart,
  Table,
  Tablet,
  User,
  UserCheck,
} from "react-feather";
import Logo from "../Logo";
import {
  closeLocationModal,
  openLocationModal,
  toggleMenu,
} from "../../app/appSlice";
import { selectAddress } from "../../address/addressSlice";
import LocationModal from "../TapTop/LocationModal";
import {
  GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
  defaultPath,
  posMasterId,
  posMasterIddefault,
} from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import axios from "axios";
import { Nav } from "react-bootstrap";
import { Location, Name } from "../../Constant";
import LocationContext from "../../_helper/Location";
import TableContext from "../../_helper/TableHolder";
import UserContext from "../../_helper/User";
import PosMasterContext from "../../_helper/PosMaster";
import { clearCache } from "../../cart/cartSlice";
import FoodList from "../FoodList";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
} from "../../utils/constants";
import { useParams } from "react-router";
import RestaurantCard, {
  withTopRatedLabel,
} from "../Restaurant/RestaurantCard";

// import { Btn } from "../../AbstractElements";

const WaiterNavBar = ({ saPosTableId = 694 }) => {
  const { isMenuOpen, isLocationModalOpen } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { address } = useSelector(selectAddress);
  const navigate = useNavigate();
  const handleToggleMenu = () => dispatch(toggleMenu());
  const handleCloseModal = () => dispatch(closeLocationModal());
  const handleOpenModal = () => dispatch(openLocationModal());
  const [locationTables, setLocationTables] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const { categoryId } = useParams();
  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ids, setReqData] = useState({});
  const [subcategoryResult, setFilteredRestaurantsMenu] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dataid, setDataid] = useState("");
  const RestaurantCardTopRated = withTopRatedLabel(RestaurantCard);

  console.log(userData);
  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();
  let tableName = waiterintable.waiterintable.table;
  console.log(waiterin);
  console.log(waiterintable.waiterintable.table);
  console.log(restaurants);

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  const handleNavigateBack = async () => {
    try {
      // await waiterin(); // Assuming waiterin is a function that returns a promise
      navigate(-1);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const openpopup = () => {
    setShowPopup(true);
  };

  const handleyes = () => {
    dispatch(clearCache(true));
    navigate(`/waiter-login`);
    setShowPopup(false);
    window.location.reload();
  };
  const handleno = () => {
    // dispatch(clearCart(true));
    setShowPopup(false);
  };

  const removeDoubleQuotes = (str) => {
    // Check if the string is enclosed in double quotes
    if (str && str.startsWith('"') && str.endsWith('"')) {
      // Remove the double quotes
      return str.slice(1, -1);
    }
    // If not enclosed in double quotes, return the original string
    return str;
  };

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        orgId: orgId,
        locationId: locationId,
        tableId: tableId,
        categoryId, // Specify the category ID you want to fetch subcategories for
      };
      setReqData(requestData);
      console.log(requestData);
      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        // Fetch categories
        const categoryResponse = await reqInstance.post(
          GET_ALL_ACTIVE_CATEGORY_FOR_PO,
          requestData
        );

        if (categoryResponse) {
          const categoryResult = categoryResponse.data;
          console.log(categoryResult);
          if (!categoryResult.hasError) {
            setRestaurants(categoryResult.categorysList);

            // Fetch subcategories for the specified category
            const subcategoryResponse = await reqInstance.post(
              GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
              requestData
            );

            if (subcategoryResponse) {
              const subcategoryResult1 = subcategoryResponse.data;

              if (!subcategoryResult1.hasError) {
                console.log(subcategoryResult1.itemsList);
                setFilteredRestaurantsMenu(subcategoryResult1.itemsList);
                setIsLoading(false);
              } else {
                console.error(
                  "Error in subcategory API response:",
                  subcategoryResult1.errorMessage
                );
                setIsLoading(false);
              }
            }
          } else {
            console.error(
              "Error in category API response:",
              categoryResult.errorMessage
            );
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orgId, locationId, tableId, categoryId]);

  useEffect(() => {
    const fetchDataAndSetVariable = async () => {
      const requestData = {
        orgId: userData.userData.saOrgId,
        locationId: waiterin.waiterin.id,
      };

      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        const response = await reqInstance.post(
          GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
          requestData
        );
        if (response.data && !response.data.hasError) {
          const tableDetails = response.data.tableDetail;
          const tablesByLocation = tableDetails
            .filter(
              (table) => table.saPosTableId === waiterintable.waiterintable.id
            )
            .map((table) => ({
              tableName: table.saPosTableName,
              locationName: table.saPosTableLocationName,
            }));

          setLocationTables(tablesByLocation);
          console.log(tablesByLocation);
        } else {
          throw new Error(
            response.data.errorMsg || "Error fetching table details"
          );
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchDataAndSetVariable();
  }, [
    userData.userData.saOrgId,
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
  ]);

  const isNavLinkActive = (path) => location.pathname === path;

  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsNavbarFixed(scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = isNavbarFixed ? "fixed-navbar " : "";
  // Check if the current path matches the specified pattern
  const isMatchingPath =
    location.pathname ===
    `/interface-one/restaurants/${orgId}/${locationId}/${tableId}/${categoryId}`;

  return (
    // <header className="d-flex relative fixed top-0 bg-white z-20 py-4 px-1 border-b col-12 shadow-sm border-gray-100">
    <header
      className={`w-full bg-white z-20 py-4 px-1 border-b col-12 shadow-sm border-gray-200  ${navbarClass}`}
    >
      <div className=" flex justify-between items-center">
        <div className={`flex items-center gap-2 md:gap-4 px-2  `}>
          {location.pathname !== "/waiter-area" && (
            <button
              // disabled={currentSlide === 0}
              onClick={handleNavigateBack}
              className="bg-gray-200 p-2 rounded-full hover:text-gray-300"
            >
              <ArrowLeft className="w-4 h-4" />{" "}
            </button>
          )}
        </div>
        <div className="flex items-center gap-2 md:gap-4 px-2">
          <Logo />
          <ul className="m-l-5">
            {waiterin.waiterin.name == null ||
            waiterin.waiterin.name === "null" ? null : (
              <li>
                <button
                  disabled={true}
                  onClick={handleOpenModal}
                  className="text-xs md:text-sm text-sm flex items-center gap-1"
                >
                  <MapPin className="w-4 h-4 text-gray-700 " />
                  {waiterin.waiterin.name}
                  {/* Additional content goes here */}
                </button>
              </li>
            )}
            <li className="text-xs md:text-sm flex items-center gap-1 mt-1 mb-1">
              <UserCheck className="w-4 h-4 text-gray-700" />
              {userData.userData.servicemanSaBpartnerName}
            </li>
            {waiterintable.waiterintable.table == null ||
            waiterintable.waiterintable.table == "null" ? null : (
              <li className="text-xs md:text-sm flex items-center gap-1">
                <Table className="w-4 h-4 text-gray-700 text-sm" />
                {/* {waiterintable.waiterintable.table} */}
                {removeDoubleQuotes(tableName)}
                {/* Additional code */}
              </li>
            )}
          </ul>
        </div>

        <ul className="text-zinc-700 ml-auto gap-2 md:gap-4 items-center d-flex relative nav-link-text">
          {/* <NavLink
            to={`/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}/cart`}
            icon={<ShoppingCart className="w-4 h-4 text-gray-700" />}
            name="Cart"
            isActive={isNavLinkActive(
              `/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}/cart`
            )}
          /> */}
          {/* <NavLink
            to={`/order/${posMasterIddefault.posMasterId}`}
            icon={<ShoppingBag className="w-4 h-4 text-gray-700" />}
            name="Placed"
            isActive={isNavLinkActive(
              `/order/${posMasterIddefault.posMasterId}`
            )}
          /> */}
          <NavLink
            to="/waiter-area"
            icon={<MapPin className="w-4 h-4 text-gray-700" />}
            name="Location"
            isActive={isNavLinkActive("/waiter-area")}
          />

          <NavLink
            to={`/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`}
            icon={<Table className="w-4 h-4 text-gray-700" />}
            name="Table"
            isActive={isNavLinkActive("/waiter")}
          />
          {/* <button
            onClick={() => openpopup()}
            icon={<LogIn className="w-4 h-4 text-gray-700" />}
            name="Log-Out"
            isActive={isNavLinkActive(
              `/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`
            )}
          /> */}
        </ul>
        <button
          onClick={() => openpopup()}
          className="bg-orange-400 rounded-md text-white"
        >
          {/* <LogIn className="w-4 h-4 text-gray-700" />
          {"Logout"} */}
          <NavLink
            icon={<LogIn className="w-4 h-4 text-gray-700" />}
            name="Logout"
            isActive={isNavLinkActive("/waiter-login")}
          />
        </button>

        {isMenuOpen ? (
          <div className="shadow-lg transition-all fixed top-full -right-[100%] bg-white h-screen p-4 px-8">
            <ul className="text-zinc-700 space-y-4">
              <NavLink
                to={`/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}/cart`}
                icon={<ShoppingCart className="w-4 h-4 text-gray-700" />}
                name="Cart"
                isActive={isNavLinkActive(
                  `/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}/cart`
                )}
              />
              {/* <NavLink
                to={`/order/${posData.posMasterId}`}
                icon={<ShoppingBag className="w-4 h-4 text-gray-700" />}
                name="Placed"
                isActive={isNavLinkActive(
                  `/order/${posMasterIddefault.posMasterId}`
                )}
              /> */}
              <NavLink
                to="/waiter-area"
                icon={
                  <MapPin className="w-4 h-4 text-gray-700 hover:text-orange-500" />
                }
                name="Location"
                isActive={isNavLinkActive("/waiter-area")}
              />
              <NavLink
                to={`/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`}
                icon={<User className="w-4 h-4 text-gray-700" />}
                name="Table"
                isActive={isNavLinkActive(
                  `/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`
                )}
              />
              {/* <NavLink
                onClick={() => openpopup()}
                icon={<LogIn className="w-4 h-4 text-gray-700" />}
                name="Log-Out"
                isActive={isNavLinkActive("/waiter-login")}
              /> */}
            </ul>
            <button
              onClick={() => openpopup()}
              className=" bg-orange-400 rounded-md text-white space-y-4"
            >
              {/* <LogIn className="w-4 h-4 text-gray-700" />
              {"Logout"} */}
              <NavLink
                icon={<LogIn className="w-4 h-4 text-gray-700" />}
                name="Logout"
                isActive={isNavLinkActive("/waiter-login")}
              />
            </button>
          </div>
        ) : null}

        <button className="hidden md:block " onClick={handleToggleMenu}>
          <AlignJustify className="w-6 h-6" />
        </button>
      </div>
      {/* Popup */}{" "}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h1 className="fs-4 text-warning">Are You Sure Want To Logout?</h1>
            <p className="fs-6 pb-3">All Cart Items Will Be Cleared</p>

            <ul
              className="p-2 d-flex inline"
              style={{
                listStyleType: "none",
                // margin: 0,
                // padding: 0,
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <li>
                <button className=" btnpop " onClick={handleyes}>
                  YES
                </button>
              </li>
              <li>
                <button className=" btnpop  " onClick={handleno}>
                  NO
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {isMatchingPath && <FoodList categoryList={restaurants} />}
    </header>
    // </header>
  );
};

// const NavLink = ({ to, icon, name, isMenuOpen }) => {
//   const underlineStyle = isMenuOpen ? "border-b-2 border-success" : "";

//   return (
//     <li>
//       <Link
//         to={to}
//         className={`p-2 relative md:px-4 hover:bg-gray-50  rounded-md flex items-center gap-2 `}
//       >
//         {icon}
//         <p className={`text-gray-700  md:block w-full ${underlineStyle}`}>
//           {name}
//         </p>
//       </Link>
//     </li>
//   );
// };

const NavLink = ({ to, icon, name, isActive }) => {
  return (
    // <li>
    <Link
      to={to}
      className={`p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2  ${
        isActive ? "nav-link-text" : ""
      }`}
    >
      {icon}
      <p className="md:block w-full nav-link-text">{name}</p>
    </Link>
    // </li>
  );
};

export default WaiterNavBar;
