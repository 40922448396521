import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import AppLayout from "../Layout/Layout";
import { Provider } from "react-redux";
import { store } from "../store/store";

const LayoutRoutes = () => {
  return (
    <>
      <Provider store={store}>
        <Routes>
          {routes.map(({ path, Component }, i) => (
            <Fragment key={i}>
              <Route element={<AppLayout />} key={i}>
                <Route path={path} element={Component} />
              </Route>{" "}
            </Fragment>
          ))}
        </Routes>
      </Provider>
    </>
  );
};

export default LayoutRoutes;
