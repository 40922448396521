import { Route } from "react-router";
import Cart from "../Components/Pages/PageLayout/Cart";
import InterfaceOne from "../Components/Pages/PageLayout/InterfaceOne";
import SamplePage from "../Components/Pages/PageLayout/SimplePage";
import OrderDetails2 from "../Components/Pages/PageLayout/ViewOrder";
import Waiter from "../Components/Pages/PageLayout/Waiter/Waiter";
import RestaurantMenu from "../Layout/RestaurantMennuItem/RestaurantMenu";
import WaiterLocation from "../Components/Pages/PageLayout/Waiter/WaiterLocation";
import Logins from "../Auth/Signin";
import { saproUrlQr } from "../Config/Config";

export const routes = [
  // //page
  {
    path: `/pages/sample/:orgId/:locationId/:tableId`,
    Component: <SamplePage />,
  },
  {
    path: `/sample-page/interface-one/:orgId/:locationId/:tableId`,
    Component: <InterfaceOne />,
  },
  {
    path: `/interface-one/restaurants/:orgId/:locationId/:tableId/:categoryId`,
    Component: <RestaurantMenu />,
  },
  {
    path: `/interface-one/restaurants/:orgId/:locationId/:tableId/cart`,
    Component: <Cart />,
  },
  {
    path: `/order/:tableId`,
    Component: <OrderDetails2 />,
  },
  {
    path: `/waiter-login`,
    Component: <Logins />,
  },
  {
    path: `/waiter-area`,
    Component: <WaiterLocation />,
  },
  {
    path: `/waiter/:orgId/:locationId`,
    Component: <Waiter />,
  },
  // {
  //   path: `resta`,
  //   Component: <RestaurantSystem />,
  // },
];

//
