import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import SvgIcon from "../../Common/Component/SvgIcon";
import { Link } from "react-router-dom";
import UserContext from "../../../_helper/User";
import LocationContext from "../../../_helper/Location";
import TableContext from "../../../_helper/TableHolder";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  selectItemsInCart,
  selectTotalPriceWithCurrency,
} from "../../../cart/cartSlice";
import { useSelector } from "react-redux";
import { Arrow } from "reactour";
import { ArrowRight } from "react-feather";

const ToggleCart = () => {
  const { totalPrice, currency } = useSelector(selectTotalPriceWithCurrency);
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const [quantity, setQuantity] = useState(0);
  const itemsInCart = useSelector(selectItemsInCart);
  const [totalprice, setTotalPrice] = useState(0.0);
  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  // ... (remaining code)

  useEffect(() => {
    // Function to fetch and update quantity and total price from local storage
    const updateCartData = () => {
      const storedQuantity = JSON.parse(
        localStorage.getItem(`cartquantity${waiterintable.waiterintable.id}`)
      );
      const storedTotalPrice = JSON.parse(
        localStorage.getItem(`carttotalPrice${waiterintable.waiterintable.id}`)
      );

      setQuantity(storedQuantity || 0);
      setTotalPrice(storedTotalPrice || 0.0);
    };

    // Initial fetch
    updateCartData();

    // Set up interval for automatic refresh every 30 seconds
    const intervalId = setInterval(updateCartData, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [waiterintable.waiterintable.id]); // Depend on waiterintable ID to trigger the effect when it changes

  const WidgetsData1WidgetsPage = {
    title: 0,
    gros: 50,
    total: 0.0,
    color: "warning",
    icon: "cart",
  };

  return (
    // <Card className="">
    <Link
      className="text-orange-500 hover:text-orange-500"
      to={`/interface-one/restaurants/${orgId}/${locationId}/${tableId}/cart`}
    >
      <Row className="widget-1 col-3">
        <Col xs={{ size: 10, offset: 2 }} className="position-relative w-full">
          <div className="widget-content">
            <div className={`widget-round ${WidgetsData1WidgetsPage.color}`}>
              <div className="bg-round">
                <SvgIcon
                  style={{ width: "16px", height: "16px" }}
                  className="svg-fill"
                  iconId={`${WidgetsData1WidgetsPage.icon}`}
                />
                {/* <SvgIcon
            style={{ width: "16px", height: "16px" }}
            className="half-circle svg-fill"
            iconId="halfcircle"
          /> */}
              </div>
            </div>{" "}
            <div>
              <h4 style={{ marginBottom: "0.5rem" }}>
                {currency}&nbsp;
                {totalPrice + ".00"}
              </h4>
              <div className="text-xs flex inline">
                {"QTY " + itemsInCart.length}
                {/* {quantity > 1 ? "Items" : "Item"} */}
              </div>
            </div>
            <h4 style={{ marginBottom: "0.5rem" }} className="flex gap-2 pt-1">
              {" "}
              {"Cart"}
              <ArrowRight width={"20px"} height={"20px"} />
            </h4>
          </div>{" "}
          {/* <div className={`font-${WidgetsData1WidgetsPage.color} f-w-500`}>
      <i
        className={`icon-arrow-${
          WidgetsData1WidgetsPage.gros < 50 ? "down" : "up"
        } icon-rotate me-1`}
      />
      <span>{`${WidgetsData1WidgetsPage.gros < 50 ? "-" : "+"}${
        WidgetsData1WidgetsPage.gros
      }%`}</span>
    </div> */}
        </Col>
      </Row>
    </Link>
  );
};

export default ToggleCart;
