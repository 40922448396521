import React, { useState, Fragment, useContext } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../cart/cartSlice";

import CartFooter from "./CartFooter";
import ItemDetailsModal from "../../Hooks/ItemDetails";
import { backendURL } from "../../Config/Config";
import TableContext from "../../_helper/TableHolder";

import { P } from "../../AbstractElements";
import ProductModal from "../ProductModel";
import UserContext from "../../_helper/User";
import defaultImage from "../../assets/images/default/def.png";
import Footer from "../Footer";

const truncateDescription = (description, wordLimit) => {
  const words = description.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return description;
};

const RestaurantMenuItem = ({ itemsList }) => {
  const dispatch = useDispatch();
  // const itemsInCart = useSelector(selectItemsInCart);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const waiterintable = useContext(TableContext);
  const userData = useContext(UserContext);
  const currentTime = new Date();
  const itemAvailableFrom = new Date(itemsList.itemAvailableFrom);
  const itemAvailableTo = new Date(itemsList.itemAvailableTo);
  // const shouldDisable = shouldDisabled();
  console.log(waiterintable.waiterintable.id);
  console.log(itemsList);
  const handleAddToCart = () => {
    dispatch(
      addToCart({
        tableName: waiterintable.waiterintable.table,
        tableId:
          parseInt(waiterintable.waiterintable.id) ||
          parseInt(userData.userData.tableId),
        id: itemsList.saItemMasterId, // Assuming that saItemMasterId is the unique identifier
        name: itemsList.name,
        currency: itemsList.currency,
        price: itemsList.sellingPice,
        imagePath: itemsList.imagePath,
        orderid: itemsList.saInvAttributesetinstanceId,
        orgName: itemsList.orgName,
        saItemMasterId: itemsList.saItemMasterId,
        saCategoryId: itemsList.saCategoryId,
        saPriceLevelId: itemsList.saPriceLevelId,
        saPosOrderTicketTypeId: itemsList.saPosOrderTicketTypeId,

        // Add other relevant properties as needed
      })
    );

    // Update the selected items state
    setSelectedItems([
      ...selectedItems,
      {
        id: itemsList.saItemMasterId,
        name: itemsList.name,
        currency: itemsList.currency,
        price: itemsList.sellingPice,
        imagePath: itemsList.imagePath,
        orderid: itemsList.saInvAttributesetinstanceId,
        orgName: itemsList.orgName,
        saItemMasterId: itemsList.saItemMasterId,
        saCategoryId: itemsList.saCategoryId,
        saPriceLevelId: itemsList.saPriceLevelId,
        saPosOrderTicketTypeId: itemsList.saPosOrderTicketTypeId,
        tableId: waiterintable.waiterintable.id || userData.userData.tableId,

        // Add other relevant properties as needed
      },
    ]);
    console.log(setSelectedItems);
  };

  const totalPrice = selectedItems.reduce(
    (total, selectedItem) => total + selectedItem.sellingPice,
    0
  );

  const handleOpenItemDetails = () => {
    setSelectedItem(itemsList);
    setOpenModal(true);
  };

  const handleCloseItemDetails = () => {
    setSelectedItem(null);
  };

  const shouldDisable = () => {
    // Check if the item is not available or the current time is outside the available range
    return (
      !itemsList.isAvailable ||
      currentTime < itemAvailableFrom ||
      currentTime > itemAvailableTo
    );
  };
  console.log(itemsList.isAvailable);

  const defimagePath = itemsList.imagePath
    ? `${backendURL}${itemsList.imagePath}`
    : defaultImage;

  return (
    <Fragment>
      <Container fluid={true}>
        <ul className="p-2 ">
          <Card
            className={` ${
              shouldDisable()
                ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                : ""
            }`}
          >
            <CardBody>
              <li
                className="p-10 py-3 flex gap-4 md:gap-8 justify-between items-center"

                // key={i}
              >
                <div
                  className="basis-8/12 space-y-2"
                  onClick={handleOpenItemDetails}
                >
                  <h2 className="fs-5 font-semibold">{itemsList.name}</h2>
                  {shouldDisable() && (
                    <P
                      attrPara={{ className: "text-danger mb-2 z-index-1" }}
                    >{`not available.`}</P>
                  )}
                  <p className="text-success fs-6 text font-semibold">
                    {itemsList.currency}&nbsp;{itemsList.sellingPice.toFixed(2)}
                  </p>
                  {/* <p className="text-base md:block">{itemsList.description}</p> */}
                  <p className="text-base md:block">
                    {truncateDescription(itemsList.description, 15)}
                  </p>
                </div>
                <div className="w-full basis-4/12 ">
                  <img
                    className="w-full h-full aspect-square object-cover rounded-md"
                    src={defimagePath}
                    // src={`${backendURL}${itemsList.imagePath}`}
                    alt="items"
                    onClick={handleOpenItemDetails}
                  />
                  <button
                    disabled={shouldDisable()}
                    onClick={handleAddToCart}
                    className={`bg-light hover-bg-orange-500 text-orange-500 font-bold px-4 p-2 px-6 rounded-md border border-warning-subtle absolute shadow-md font-bold p-2 px-6 rounded-md absolute shadow-md left-[50%] -bottom-5 -translate-x-[50%] ${
                      shouldDisable()
                        ? "disabled disabled:bg-orange-500/50 disabled:cursor-not-allowed "
                        : ""
                    }`}
                  >
                    ADD
                  </button>
                </div>
              </li>
            </CardBody>
            {shouldDisable() && (
              <P
                attrPara={{ className: "text-danger mb-2 z-index-1" }}
              >{`Normally Available from ${itemsList.itemAvailableFrom} to ${itemsList.itemAvailableTo}`}</P>
            )}
          </Card>
        </ul>

        {selectedItems.length > 0 && (
          <CartFooter
            totalItems={selectedItems.length}
            totalPrice={totalPrice}
          />
        )}

        {selectedItem && (
          <ItemDetailsModal
            item={selectedItem}
            onClose={handleCloseItemDetails}
          />
        )}

        {selectedItem && (
          <ProductModal
            value={openModal}
            dataid={selectedItem.saItemMasterId}
            setOpenModal={() => setOpenModal(false)}
            name={selectedItem.name}
            currency={selectedItem.currency}
            description={selectedItem.description}
            price={selectedItem.sellingPice}
            image={selectedItem.imagePath}
            // image={selectedItem.defimagePath}
            isavailable={selectedItem.isAvailable}
            itemAvailableFrom={selectedItem.itemAvailableFrom}
            itemAvailableTo={selectedItem.itemAvailableTo}
            handleAddToCart={handleAddToCart}
          />
        )}
       
      </Container>
    </Fragment>
  );
};

export default RestaurantMenuItem;
