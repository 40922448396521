import React from "react";
import { Card } from "reactstrap";
// import { H1, H4 } from "../../../../AbstractElements";
import SvgIcon from "../../../Common/Component/SvgIcon";
// import SvgIcon from "../Component/SvgIcon";

const Widgets3 = ({ data }) => {
  return (
    <Card className="widget-1 bg-primary">
      <div className="widget-content card pt-2 mb-1">
        <div className={`widget-round ${data.color}`}>
          <div className="bg-round">
            <SvgIcon className="" iconId={`${data.icon}`} />
            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
          </div>
        </div>
        <div>
          <h3 className="fs-6 text-primary d-flex align-items-center">
            <span className="table-heading">Table: </span>&nbsp; {data.table}
          </h3>
          {/* <H4>{data.total}</H4> */}
          {/* <span className="fs-6 semibold text-black">{data.title}</span> */}
        </div>
      </div>
      <div className="p-l-2">
        {" "}
        <h3 className="fs-5 text-primary d-flex align-items-center "> </h3>
      </div>

      <div className="p-l-5">
        {" "}
        <h3 className="fs-5 text-black bold d-flex align-items-center">
          {" "}
          <span className="table-heading  ">Total: </span> {data.subtotal}{" "}
        </h3>
      </div>
      <div className="fs-6 text-black d-flex  align-item-center justify-content-center p-l-10 p-r-10 bg-primary text-white">
        {data.title}
      </div>
      {/* <div className={`font-${data.color} f-w-500`}>
          <i
            className={`icon-arrow-${
              data.gros < 50 ? "down" : "up"
            } icon-rotate me-1`}
          />
          <span>{`${data.gros < 50 ? "-" : "+"}${data.gros}%`}</span>
        </div> */}
      {/* </CardBody> */}
    </Card>
  );
};

export default Widgets3;
