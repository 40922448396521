import { Link } from "react-router-dom";

import { selectTotalPriceWithCurrency } from "../cart/cartSlice";
import { useSelector } from "react-redux";
import UserContext from "../_helper/User";
import { useContext } from "react";

const Logo = () => {
  // const { orgName } = useSelector(selectTotalPriceWithCurrency);
  const userData = useContext(UserContext);
  console.log(userData);
  return (
    <Link
      // to=""
      data-testid="logo"
      className="text-xl md:text-2xl font-semibold flex items-center "
    >
      {/* 🍔 <span className=" md:block logo">Red Baloon </span>  */}
      <span className="text-orange-500 md:block logo">
        {userData.userData.orgName}
      </span>
    </Link>
  );
};

export default Logo;
