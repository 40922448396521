import { useState, useEffect } from "react";
import RestaurantMenuItem from "./index";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
} from "../../utils/constants";
import axios from "axios";
import { backendURL } from "../../Config/Config";
import { useParams } from "react-router";
import ProductModal from "../ProductModel";
import FoodList from "../FoodList";
import RestaurantCard, {
  withTopRatedLabel,
} from "../Restaurant/RestaurantCard";
import UserContext from "../../_helper/User";
import LocationContext from "../../_helper/Location";
import TableContext from "../../_helper/TableHolder";
import { useContext } from "react";
import ToggleCart from "../../Components/Pages/PageLayout/ToggleCart";
import Footer from "../Footer";
// import "../FoodList/menulist.css";

const RestaurantMenu = () => {
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const { categoryId } = useParams();
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [isFoodListFixed, setIsFoodListFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [ids, setReqData] = useState({});
  const [subcategoryResult, setFilteredRestaurantsMenu] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dataid, setDataid] = useState("");
  const RestaurantCardTopRated = withTopRatedLabel(RestaurantCard);
  console.log(userData);
  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  const handleToggleAccordion = (index) => {
    if (index === activeAccordionIndex) {
      setActiveAccordionIndex(null);
    } else {
      setActiveAccordionIndex(index);
    }
  };
  const onOpenModal = (productId) => {
    setOpenModal(true);
    setDataid(productId);
  };
  console.log(userData.userData);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        orgId: orgId,
        locationId: locationId,
        tableId: tableId,

        categoryId, // Specify the category ID you want to fetch subcategories for
      };
      setReqData(requestData);
      console.log(requestData);
      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        // Fetch categories
        const categoryResponse = await reqInstance.post(
          GET_ALL_ACTIVE_CATEGORY_FOR_PO,
          requestData
        );

        if (categoryResponse) {
          const categoryResult = categoryResponse.data;
          console.log(categoryResult);
          if (!categoryResult.hasError) {
            setRestaurants(categoryResult.categorysList);

            // Fetch subcategories for the specified category
            const subcategoryResponse = await reqInstance.post(
              GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
              requestData
            );

            if (subcategoryResponse) {
              const subcategoryResult1 = subcategoryResponse.data;

              if (!subcategoryResult1.hasError) {
                console.log(subcategoryResult1.itemsList);
                setFilteredRestaurantsMenu(subcategoryResult1.itemsList);
                setIsLoading(false);
              } else {
                console.error(
                  "Error in subcategory API response:",
                  subcategoryResult1.errorMessage
                );
                setIsLoading(false);
              }
            }
          } else {
            console.error(
              "Error in category API response:",
              categoryResult.errorMessage
            );
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orgId, locationId, tableId, categoryId]);

  const handleScroll = () => {
    // Add logic to determine when to fix the FoodList component
    const scrollPosition = window.scrollY;
    const threshold = 50; // Adjust this value based on your needs

    setIsFoodListFixed(scrollPosition > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="card ">
      {/* <div
        className={` ${
          isFoodListFixed ? "fixedFoodList mt-4 shadow-sm border-gray-200" : ""
        }`}
      >
        <FoodList categoryList={restaurants} />
       
      </div> */}
      {/* <FoodList categoryList={restaurants} /> */}
      <div className="mt-4">
        <ToggleCart />
      </div>

      {/* Other components or UI elements */}
      {subcategoryResult.map((item, index) => (
        <RestaurantMenuItem
          key={index}
          itemsList={item}
          index={index}
          activeIndex={activeAccordionIndex}
          setActiveIndex={handleToggleAccordion}
          onOpenModal={onOpenModal}
        />
      ))}
      {openModal && (
        <ProductModal
          value={openModal}
          setOpenModal={setOpenModal}
          dataid={dataid}
        />
      )}
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default RestaurantMenu;
