// import React from "react";
// import { CDN_URL } from "../utils/constants";

// const ItemDetailsModal = ({ item, onClose }) => {
//   return (
//     <div className="item-details-modal">
//       <div className="modal-content">
//         <div className="modal-header">
//           <h2>{item.card.info.name}</h2>
//           <button onClick={onClose}>Close</button>
//         </div>
//         <div className="modal-body">
//           <div className="item-image">
//             <img
//               src={CDN_URL + item.card.info.imageId}
//               alt={item.card.info.name}
//             />
//           </div>
//           <div className="item-description">
//             <p>{item.card.info.description}</p>
//             <p>Price: LKR{item.card.info.price * 350}</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ItemDetailsModal;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import { decreaseItemQuantity, increaseItemQuantity } from "../cart/cartSlice";

const ItemDetailsModal = ({ selectedItem }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const openItemDetails = () => {
    setIsModalOpen(true);
  };

  const closeItemDetails = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {selectedItem && (
        <>
          <button className="btn btn-primary" onClick={openItemDetails}>
            Open Item Details
          </button>
          {isModalOpen && (
            <Modal
              show={isModalOpen}
              onHide={closeItemDetails}
              animation={false}
              style={{
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "#212529" }}>
                  Item Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ color: "#212529" }}>
                    {selectedItem.card.info.name}
                  </h2>
                  <img
                    src={selectedItem.card.info.imageId}
                    alt={selectedItem.card.info.name}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <p style={{ color: "#343a40" }}>
                  {selectedItem.card.info.info}
                </p>
                <p style={{ color: "#343a40" }}>
                  Price: {selectedItem.card.info.price}
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={closeItemDetails}
                  style={{
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#343a40",
                      color: "#fff",
                    },
                  }}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default ItemDetailsModal;


