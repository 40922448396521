import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./index";
import { logindefault } from "../../utils/constants";

const UserProvider = (props) => {
  const [userData, setUserData] = useState({});
  // const [partsData, setParts] = useState({});

  const addUserData = (user_data) => {
    console.log("Data Set");
    // Convert the parts array into an object with keys
    // const partsObject = {
    //   qrOrgId: user_data?.parts[1], // Assuming parts[1] corresponds to orgName
    //   qrlocationId: user_data?.parts[2], // Assuming parts[2] corresponds to saOrgId
    //   qrtableId: user_data?.parts[3], // Assuming parts[3] corresponds to servicemanSaBpartnerId
    // };
    // setParts(partsObject);
    setUserData(user_data);
  };

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("DataloggedUser");
    if (storedData) {
      setUserData(JSON.parse(storedData));
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("DataloggedUser", JSON.stringify(userData));
  }, [userData]);

  console.log(userData);
  // console.log(partsData);

  // Access the parts array from userData and use it
  // const parts = userData;

  // Now you can use the parts array in your component logic
  // useEffect(() => {
  //   // Example: Logging the parts array
  //   console.log("Parts from userData:", parts);

  //   // You can use the parts array in your logic here
  //   // For example, you can pass it to another component or perform any other operation
  // }, [parts]);

  // console.log(userData.saOrgId);

  return (
    <Context.Provider
      value={{
        ...props,
        userData,
        // partsData,
        addUserData: addUserData,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;
