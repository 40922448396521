import { createSlice, current } from "@reduxjs/toolkit";
import { useContext } from "react";
import TableContext from "../_helper/TableHolder";
import { useDispatch } from "react-redux";

// import { storeCartHistory } from "./OrderedSlice";

const tableIdFromLocalStorage =
  JSON.parse(localStorage.getItem("waiterintable_tableid")) || [];
const itemsFromLocalStorage =
  JSON.parse(localStorage.getItem(`cart${tableIdFromLocalStorage}`)) || [];
const historyFromLocalStorage =
  JSON.parse(localStorage.getItem(`carthistory${tableIdFromLocalStorage}`)) ||
  [];

const initialState = {
  tableId: tableIdFromLocalStorage,
  items: itemsFromLocalStorage,
  orderPlaced: false,
  tables: {},
};

const cartSlice = createSlice({
  name: `cart`,
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const tableId = action.payload.tableId;
      console.log(action.payload);
      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }

      const newItem = {
        item: action.payload,
        quantity: 1,
        showItemCount: true,
      };

      // Check if the item is already in the cart
      const existingItemIndex = state.items.findIndex(
        (cartItem) => cartItem?.item?.id === action.payload.id
      );

      console.log(existingItemIndex);

      if (existingItemIndex !== -1) {
        // If the item is already in the cart, update the quantity
        state.items[existingItemIndex].quantity += 1;
        console.log(state.items);
      } else {
        // If the item is not in the cart, add a new item
        state.items = [...state.items, newItem];
        console.log(state.items);
      }

      localStorage.setItem(`cart${tableId}`, JSON.stringify(state.items));
      // storeCartHistory(state);
    },

    setOrderPlaced: (state, action) => {
      state.orderPlaced = action.payload;
    },
    setShowItemCount: (state, action) => {
      state.showItemCount = action.payload;
    },

    clearOrderPlaced: (state) => {
      state.orderPlaced = false;
    },
    removeFromCart: (state, action) => {
      const tableId = action.payload.tableId;
      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }
      state.items = state.items.filter(
        (cartItem) => cartItem?.item?.id !== action.payload.id
      );
      localStorage.setItem(`cart${tableId}`, JSON.stringify(state.items));
    },
    increaseItemQuantity: (state, action) => {
      const tableId = action.payload.tableId;
      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }
      const { id } = action.payload;
      const itemToIncrease = state.items.find(
        (cartItem) => cartItem?.item?.id === id
      );

      if (itemToIncrease) {
        itemToIncrease.quantity += 1;
        localStorage.setItem(`cart${tableId}`, JSON.stringify(state.items));
      }
    },

    decreaseItemQuantity: (state, action) => {
      const tableId = action.payload.tableId;
      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }
      const { id } = action.payload;
      const itemToDecrease = state.items.find(
        (cartItem) => cartItem?.item?.id === id
      );

      if (itemToDecrease && itemToDecrease.quantity > 1) {
        itemToDecrease.quantity -= 1;
        localStorage.setItem(`cart${tableId}`, JSON.stringify(state.items));
      }
    },

    clearCart: (state, action) => {
      const tableId = action.payload.tableId;

      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }

      // Remove only the items array from the state
      state.items = [];

      // Update the local storage with the new items
      localStorage.setItem(
        `historycart${tableIdFromLocalStorage}`,
        JSON.stringify(historyFromLocalStorage)
      );

      // Remove the cart data from local storage
      // Clear specific local storage items
      for (let key in localStorage) {
        if (
          key.startsWith("cart") ||
          key.startsWith("cartquantity") ||
          key.startsWith("cartdata") ||
          key.startsWith("currency") ||
          key.startsWith("totals")
        ) {
          localStorage.removeItem(key);
        }
      }
    },

    clearCache: (state, action) => {
      const tableId = action.payload.tableId;

      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }

      // Remove only the items array from the state
      state.items = [];

      // Remove the cart data from local storage
      // Clear specific local storage items
      // Iterate through local storage keys
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        // Check if the key does not contain "carthistory"
        if (!key.includes("carthistory")) {
          // Remove the item with the current key
          localStorage.removeItem(key);
        }
      }

      // Note: The above approach iterates through the local storage keys and removes items in place.
      // If you prefer to create a new object without certain keys, you can do the following:

      const filteredLocalStorage = Object.keys(localStorage).reduce(
        (accumulator, key) => {
          // Check if the key contains "carthistory"
          if (key.includes("carthistory")) {
            // Keep the item in the new object
            accumulator[key] = localStorage[key];
          }

          return accumulator;
        },
        {}
      );

      // Clear the existing local storage
      localStorage.clear();

      // Set the filtered items back to local storage
      Object.keys(filteredLocalStorage).forEach((key) => {
        localStorage.setItem(key, filteredLocalStorage[key]);
      });
    },

    addNotesToItem: (state, action) => {
      const tableId = action.payload.tableId;
      // Create a table-specific entry if it doesn't exist
      if (!state.tables[tableId]) {
        state.tables[tableId] = [];
      }
      const { id, notes } = action.payload;
      const itemToUpdate = state.items.find(
        (cartItem) => cartItem?.item?.id === id
      );

      if (itemToUpdate) {
        itemToUpdate.notes = notes;
        localStorage.setItem(`cart${tableId}`, JSON.stringify(state.items));
      }
    },
    setItemsInCart: (state, action) => {
      const tableId = action.payload.tableId;
      state.items = action.payload.items;

      // Update the local storage with the new items
      localStorage.setItem(
        `historycart${tableId}`,
        JSON.stringify(action.payload.items)
      );
    },

    // Extra reducers for handling async actions or other slices
    extraReducers: (builder) => {
      builder.addCase(loadStoredCarts, (state, action) => {
        const { tables } = action.payload;
        const dispatch = useDispatch(); // Use useDispatch to get the dispatch function

        for (const tableId in tables) {
          if (tables.hasOwnProperty(tableId)) {
            const storedItems =
              JSON.parse(localStorage.getItem(`cart${tableId}`)) || [];

            // Dispatch the setItemsInCart action
            dispatch(setItemsInCart({ tableId, items: storedItems }));
          }
        }
      });
    },
  },
});

export const newAddition = ({ newItem }) => newItem?.items;

export const selectItemsInCart = ({ cart }) => cart?.items;
export const PlacedItems = ({ cart }) => cart?.items;

export const selectTotalPrice = ({ cart }) => {
  const currency = cart?.items[0]?.item?.currency; // Assuming the currency is the same for all items

  return cart?.items.reduce((total, cartItem) => {
    localStorage.setItem("currency", JSON.stringify(currency));
    localStorage.setItem(
      "totals",
      JSON.stringify(total + cartItem.item.price * cartItem.quantity)
    );
    return total + cartItem.item.price * cartItem.quantity;
  }, 0);
};

// export const selectTotalPrice = ({ cart }, tableId) => {
//   const currency = cart?.items[0]?.item?.currency; // Assuming the currency is the same for all items

//   const itemsForTable = cart?.tables[tableId] || [];

//   return itemsForTable.reduce((total, cartItem) => {
//     localStorage.setItem("currency", JSON.stringify(currency));
//     localStorage.setItem(
//       "totals",
//       JSON.stringify(total + cartItem.item.price * cartItem.quantity)
//     );
//     return total + cartItem.item.price * cartItem.quantity;
//   }, 0);
// };

export const selectTotalPriceWithCurrency = (state) => {
  const totalPrice = selectTotalPrice(state);
  const currency = selectItemsInCart(state)?.[0]?.item?.currency;
  const orderids = selectItemsInCart(state)?.[0]?.item?.orderid;
  const orgName = selectItemsInCart(state)?.[0]?.item?.orgName;
  const PosMId = selectItemsInCart(state)?.[0]?.item?.saPosMId;
  const saItemMasterId = selectItemsInCart(state)?.[0]?.item?.saItemMasterId;
  const sellingPrice = selectItemsInCart(state)?.[0]?.item?.sellingPrice;
  const saCategoryId = selectItemsInCart(state)?.[0]?.item?.saCategoryId;
  const saPriceLevelId = selectItemsInCart(state)?.[0]?.item?.saPriceLevelId;
  const saPosOrderTicketTypeId =
    selectItemsInCart(state)?.[0]?.item?.saPosOrderTicketTypeId;

  return {
    totalPrice,
    currency,
    orderids,
    orgName,
    PosMId,
    saItemMasterId,
    sellingPrice,
    saCategoryId,
    saPriceLevelId,
    saPosOrderTicketTypeId,
  };
};

export const {
  addToCart,
  removeFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  clearCart,
  clearCache,
  setOrderPlaced,
  clearOrderPlaced,
  setShowItemCount,
  addNotesToItem,
  setItemsInCart,
  loadStoredCarts,
} = cartSlice.actions;

export default cartSlice.reducer;

// import { createSlice, current } from "@reduxjs/toolkit";
// import { useContext } from "react";
// import TableContext from "../_helper/TableHolder";
// import { useDispatch } from "react-redux";

// // import { storeCartHistory } from "./OrderedSlice";

// const tableIdFromLocalStorage =
//   JSON.parse(localStorage.getItem("waiterintable_tableid")) || [];
// const itemsFromLocalStorage =
//   JSON.parse(localStorage.getItem(`cart${tableIdFromLocalStorage}`)) || [];

// const initialState = {
//   tableId: tableIdFromLocalStorage,
//   orderPlaced: false,
//   tables: {
//     [tableIdFromLocalStorage]: [itemsFromLocalStorage],
//   },
// };

// const cartSlice = createSlice({
//   name: `cart`,
//   initialState,
//   reducers: {
//     addToCart: (state, action) => {
//       const { tableId } = action.payload;
//       console.log(action.payload);
//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       // Ensure state.tables[tableId] is initialized as an array
//       state.tables[tableId] = state.tables[tableId] || [];

//       const newItem = {
//         item: action.payload,
//         quantity: 1,
//         showItemCount: true,
//       };

//       // Check if the item is already in the cart
//       const existingItemIndex = state.tables[tableId].findIndex(
//         (cartItem) => cartItem?.item?.id === action.payload.id
//       );

//       if (existingItemIndex !== -1) {
//         // If the item is already in the cart, update the quantity
//         state.tables[tableId][existingItemIndex].quantity += 1;
//       } else {
//         // If the item is not in the cart, add a new item
//         state.tables[tableId].push(newItem);
//       }
//       console.log(existingItemIndex);
//       localStorage.setItem(
//         `cart${tableId}`,
//         JSON.stringify(state.tables[tableId])
//       );
//     },

//     setOrderPlaced: (state, action) => {
//       state.orderPlaced = action.payload;
//     },
//     setShowItemCount: (state, action) => {
//       state.showItemCount = action.payload;
//     },

//     clearOrderPlaced: (state) => {
//       state.orderPlaced = false;
//     },
//     removeFromCart: (state, action) => {
//       const { tableId } = action.payload;

//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       state.tables[tableId] = state.tables[tableId].filter(
//         (cartItem) => cartItem?.item?.id !== action.payload.id
//       );

//       localStorage.setItem(
//         `cart${tableId}`,
//         JSON.stringify(state.tables[tableId])
//       );
//     },

//     increaseItemQuantity: (state, action) => {
//       const { tableId, id } = action.payload;

//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       const itemToIncrease = state.tables[tableId].find(
//         (cartItem) => cartItem?.item?.id === id
//       );

//       if (itemToIncrease) {
//         itemToIncrease.quantity += 1;
//         localStorage.setItem(
//           `cart${tableId}`,
//           JSON.stringify(state.tables[tableId])
//         );
//       }
//     },

//     decreaseItemQuantity: (state, action) => {
//       const { tableId, id } = action.payload;

//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       const itemToDecrease = state.tables[tableId].find(
//         (cartItem) => cartItem?.item?.id === id
//       );

//       if (itemToDecrease && itemToDecrease.quantity > 1) {
//         itemToDecrease.quantity -= 1;
//         localStorage.setItem(
//           `cart${tableId}`,
//           JSON.stringify(state.tables[tableId])
//         );
//       }
//     },

//     clearCart: (state, action) => {
//       const { tableId } = action.payload;

//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       const currentItems = state.tables[tableId];

//       state.tables[tableId] = [];
//       localStorage.setItem(`cartHistory`, JSON.stringify(currentItems));
//       localStorage.removeItem(`cart${tableId}`);
//     },

//     addNotesToItem: (state, action) => {
//       const { tableId, id, notes } = action.payload;

//       if (!state.tables[tableId]) {
//         state.tables[tableId] = [];
//       }

//       const itemToUpdate = state.tables[tableId].find(
//         (cartItem) => cartItem?.item?.id === id
//       );

//       if (itemToUpdate) {
//         itemToUpdate.notes = notes;
//         localStorage.setItem(
//           `cart${tableId}`,
//           JSON.stringify(state.tables[tableId])
//         );
//       }
//     },
//     setItemsInCart: (state, action) => {
//       const tableId = action.payload.tableId;
//       state.items = action.payload.items;

//       // Update the local storage with the new items
//       localStorage.setItem(
//         `historycart${tableId}`,
//         JSON.stringify(action.payload.items)
//       );
//     },

//     // Extra reducers for handling async actions or other slices
//     extraReducers: (builder) => {
//       builder.addCase(loadStoredCarts, (state, action) => {
//         const { tables } = action.payload;
//         const dispatch = useDispatch(); // Use useDispatch to get the dispatch function

//         for (const tableId in tables) {
//           if (tables.hasOwnProperty(tableId)) {
//             const storedItems =
//               JSON.parse(localStorage.getItem(`cart${tableId}`)) || [];

//             // Dispatch the setItemsInCart action
//             dispatch(setItemsInCart({ tableId, items: storedItems }));
//           }
//         }
//       });
//     },
//   },
// });

// export const newAddition = ({ newItem }) => newItem?.items;

// export const selectItemsInCart = ({ cart }) => cart?.tables[cart?.tableId];

// export const PlacedItems = ({ cart }) => cart?.items;

// export const selectTotalPrice = ({ cart }) => {
//   const items = cart?.tables[cart?.tableId] || [];

//   if (items.length === 0) {
//     return 0; // or any default value if items are not available
//   }

//   const currency = items[0]?.item?.currency;

//   return items.reduce((total, cartItem) => {
//     localStorage.setItem("currency", JSON.stringify(currency));
//     localStorage.setItem(
//       "totals",
//       JSON.stringify(total + cartItem.item.price * cartItem.quantity)
//     );
//     return total + cartItem.item.price * cartItem.quantity;
//   }, 0);
// };

// // export const selectTotalPrice = ({ cart }, tableId) => {
// //   const currency = cart?.items[0]?.item?.currency; // Assuming the currency is the same for all items

// //   const itemsForTable = cart?.tables[tableId] || [];

// //   return itemsForTable.reduce((total, cartItem) => {
// //     localStorage.setItem("currency", JSON.stringify(currency));
// //     localStorage.setItem(
// //       "totals",
// //       JSON.stringify(total + cartItem.item.price * cartItem.quantity)
// //     );
// //     return total + cartItem.item.price * cartItem.quantity;
// //   }, 0);
// // };

// export const selectTotalPriceWithCurrency = (state) => {
//   const totalPrice = selectTotalPrice(state);
//   const currency = selectItemsInCart(state)?.[0]?.item?.currency;
//   const orderids = selectItemsInCart(state)?.[0]?.item?.orderid;
//   const orgName = selectItemsInCart(state)?.[0]?.item?.orgName;
//   const PosMId = selectItemsInCart(state)?.[0]?.item?.saPosMId;
//   const saItemMasterId = selectItemsInCart(state)?.[0]?.item?.saItemMasterId;
//   const sellingPrice = selectItemsInCart(state)?.[0]?.item?.sellingPrice;
//   const saCategoryId = selectItemsInCart(state)?.[0]?.item?.saCategoryId;
//   const saPriceLevelId = selectItemsInCart(state)?.[0]?.item?.saPriceLevelId;
//   const saPosOrderTicketTypeId =
//     selectItemsInCart(state)?.[0]?.item?.saPosOrderTicketTypeId;

//   return {
//     totalPrice,
//     currency,
//     orderids,
//     orgName,
//     PosMId,
//     saItemMasterId,
//     sellingPrice,
//     saCategoryId,
//     saPriceLevelId,
//     saPosOrderTicketTypeId,
//   };
// };

// export const {
//   addToCart,
//   removeFromCart,
//   increaseItemQuantity,
//   decreaseItemQuantity,
//   clearCart,
//   setOrderPlaced,
//   clearOrderPlaced,
//   setShowItemCount,
//   addNotesToItem,
//   setItemsInCart,
//   loadStoredCarts,
// } = cartSlice.actions;

// export default cartSlice.reducer;
