import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectItemsInCart } from "../../cart/cartSlice";
import {
  AlignJustify,
  ArrowLeft,
  ArrowUp,
  MapPin,
  ShoppingBag,
  ShoppingCart,
} from "react-feather";
import Logo from "../Logo";
import {
  closeLocationModal,
  openLocationModal,
  toggleMenu,
} from "../../app/appSlice";
import { selectAddress } from "../../address/addressSlice";
import {
  GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
  posMasterIddefault,
} from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import TableContext from "../../_helper/TableHolder";
import UserContext from "../../_helper/User";
import TableHolderProvider from "../../_helper/TableHolder/TableHolderProvider";

const NavBar = ({ saPosTableId }) => {
  const { isMenuOpen, isLocationModalOpen } = useSelector((state) => state.app);
  const showItemCount = useSelector((state) => state.cart.showItemCount);
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const itemsInCart = useSelector(selectItemsInCart);
  const { address } = useSelector(selectAddress);
  const navigate = useNavigate();
  const handleToggleMenu = () => dispatch(toggleMenu());
  const location = useLocation();
  const handleCloseModal = () => dispatch(closeLocationModal());
  const handleOpenModal = () => dispatch(openLocationModal());
  const [locationTables, setLocationTables] = useState({});
  const [locationexactTables, setLocationexactTables] = useState([0]);
  const [POSMasterStatus, setPOSMasterStatusMenu] = useState([]);
  const userData = useContext(UserContext);
  // const { WaiterInTable } = useContext(TableHolderProvider);
  const [isLoading, setIsLoading] = useState(true);

  console.log(locationTables);

  console.log(userData.userData.tableId);
  useEffect(() => {
    const fetchDataAndSetVariable = async () => {
      try {
        const response = await axios.post(
          backendURL + GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
          {
            orgId: userData.userData.saOrgId,
            locationId: userData.userData.locationId,
          }
        );

        if (response.data && !response.data.hasError) {
          const tableDetails = response.data.tableDetail;
          setLocationTables(tableDetails);
        } else {
          throw new Error(
            response.data.errorMsg || "Error fetching table details"
          );
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchDataAndSetVariable();
  }, [userData.userData.saOrgId, userData.userData.locationId, saPosTableId]); // Include parameters in the dependency array
  useEffect(() => {
    const id = parseInt(userData.userData.tableId);

    if (locationTables && locationTables.length > 0) {
      const tablesByLocation = locationTables
        .filter((table) => parseInt(table.saPosTableId) === id) // Filter by saPosTableId
        .map((table) => table.saPosTableName);

      console.log(locationTables);
      // Assuming you have a state called setLocationTables
      setLocationexactTables(tablesByLocation);
      // WaiterInTable(locationexactTables);

      console.log(tablesByLocation);
    }
  }, [userData.userData.tableId, locationTables]);

  const handleNavigateBack = async () => {
    try {
      // await waiterin(); // Assuming waiterin is a function that returns a promise
      navigate(-1);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  return (
    <header className="sticky w-full top-0 bg-white z-20 py-4 px-2 border-b shadow-sm border-gray-100">
      <div className="container-max flex justify-between items-center">
        <div className={`flex items-center gap-2 md:gap-4 px-2  `}>
          {!location.pathname.startsWith("/sample-page/interface-one") && (
            <button
              onClick={handleNavigateBack}
              className="bg-gray-200 p-2 rounded-full hover:text-gray-300"
            >
              <ArrowLeft className="w-4 h-4" />{" "}
            </button>
          )}
        </div>
        <div className="flex items-center gap-2 md:gap-4 px-2">
          <Logo />

          <button
            disabled
            // onClick={handleOpenModal}
            className="text-xs md:text-sm flex items-center gap-1"
          >
            <MapPin className="w-4 h-4 text-gray-700" />
            {locationexactTables}
            <ArrowUp className="w-4 h-4 text-orange-500" />
          </button>
        </div>
        {/* <Logo /> */}

        <ul className="text-zinc-700 ml-auto gap-2 md:gap-4 items-center d-flex relative">
          {/* <li>
            <Link
              to="/search"
              className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <Search className="w-4 h-4 text-gray-700" />{" "}
              <p className="hidden md:block">Search</p>
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <Home className="w-4 h-4 text-gray-700" />{" "}
              <p className="hidden md:block">Home</p>
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <Radio className="w-4 h-4 text-gray-700" />{" "}
              <p className="hidden md:block">About</p>
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <Phone className="w-4 h-4 text-gray-700" />{" "}
              <p className="hidden md:block">Contact</p>
            </Link>
          </li> */}
          <li>
            <Link
              to={`/interface-one/restaurants/${userData.userData.saOrgId}/${userData.userData.locationId}/${userData.userData.tableId}/cart`}
              className="p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <ShoppingCart className="w-4 h-4 text-gray-700 " />{" "}
              <p className="nav-link-text md:block">Cart</p>
              {
                <p className="absolute -top-1 -right-1 bg-orange-500 text-white flex justify-center items-center w-5 h-5 text-xs rounded-full">
                  {itemsInCart.length}
                </p>
              }
            </Link>
          </li>{" "}
          <li>
            <Link
              to={`/order/${posMasterIddefault.posMasterId}`}
              className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
            >
              <i className="w-4 h-4 text-gray-700 fw-bold fa fa-wpforms" />{" "}
              <p className="nav-link-text md:block">Placed</p>
              {showItemCount && (
                <p className="absolute -top-1 -right-1 bg-orange-500 text-white flex justify-center items-center w-5 h-5 text-xs rounded-full">
                  {itemsInCart.length}
                </p>
              )}
            </Link>
          </li>
        </ul>
        {/* {isLoading ? null : isAuthenticated ? (
          <button
            onClick={() => loginWithRedirect()}
            className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
          >
            Logout{" "}
          </button>
        ) : (
          <button
            onClick={() => loginWithRedirect()}
            className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
          >
            Login
          </button>
        )} */}

        {!isMenuOpen ? (
          <div className="shadow-lg transition-all fixed top-full -right-[100%] bg-white h-screen p-4 px-8">
            <>
              <ul className="text-zinc-700  space-y-4">
                {/* <li>
                  <Link
                    to="/search"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Search className="w-4 h-4 text-gray-700" /> <p>Search</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Home className="w-4 h-4 text-gray-700" /> <p>Home</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Radio className="w-4 h-4 text-gray-700" /> <p>About</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Phone className="w-4 h-4 text-gray-700" /> <p>Contact</p>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={`/interface-one/restaurants/${userData.userData.orgId}/${userData.userData.locationId}/${userData.userData.tableId}/cart`}
                    className="p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <ShoppingBag className="w-4 h-4 text-gray-700" />{" "}
                    <p>Cart</p>
                    {
                      <p className="absolute -top-1 -right-1 bg-orange-500 text-white flex justify-center items-center w-5 h-5 text-xs rounded-full">
                        {itemsInCart.length}
                      </p>
                    }
                  </Link>
                </li>
              </ul>
              {/* {isLoading ? null : isAuthenticated ? (
                <button
                  onClick={() => loginWithRedirect()}
                  className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
                >
                  Logout{" "}
                </button>
              ) : (
                <button
                  onClick={() => loginWithRedirect()}
                  className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
                >
                  Login
                </button>
              )} */}
            </>
          </div>
        ) : (
          <div className="shadow-lg transition-all  absolute top-full right-0 bg-white h-screen p-4 px-8">
            <>
              <ul className="text-zinc-700 space-y-4">
                {/* <li>
                  <Link
                    to="/search"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Search className="w-4 h-4 text-gray-700" /> <p>Search</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Home className="w-4 h-4 text-gray-700" /> <p>Home</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Radio className="w-4 h-4 text-gray-700" /> <p>About</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <Phone className="w-4 h-4 text-gray-700" /> <p>Contact</p>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={`/interface-one/restaurants/${userData.userData.saOrgId}/${userData.userData.locationId}/${userData.userData.tableId}/cart`}
                    className="p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <ShoppingBag className="w-4 h-4 text-gray-700" />{" "}
                    <p>Cart</p>
                    {
                      <p className="absolute -top-1 -right-1 bg-orange-500 text-white flex justify-center items-center w-5 h-5 text-xs rounded-full">
                        {itemsInCart.length}
                      </p>
                    }
                  </Link>
                </li>
              </ul>
              {/* {isLoading ? null : isAuthenticated ? (
                <button
                  onClick={() => loginWithRedirect()}
                  className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
                >
                  Logout{" "}
                </button>
              ) : (
                <button
                  onClick={() => loginWithRedirect()}
                  className="ml-4 bg-orange-400 text-white p-2 px-4 rounded-md items-center gap-2 hidden md:flex"
                >
                  Login
                </button>
              )} */}
            </>
          </div>
        )}

        <button className=" md:block hidden" onClick={handleToggleMenu}>
          <AlignJustify className="w-6 h-6" />
        </button>
      </div>
    </header>
  );
};
export default NavBar;
