import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectTotalPrice,
  selectItemsInCart,
  selectTotalPriceWithCurrency,
  setOrderPlaced,
  clearOrderPlaced,
  // setShowItemCount,
  clearCart,
  storeCartHistory,
  storeCartToHistory,
} from "../../cart/cartSlice";
import CartItemList from "./CartItemList";
import { Date, DateAndTime } from "../../Constant";
import OrderDetails2 from "../../Components/Pages/PageLayout/ViewOrder";
import axios from "axios";
import {
  GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
  GET_POS_MASTER_STATUS_FOR_PO,
  posMasterIddefault,
} from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import PosMasterContext from "../../_helper/PosMaster";
import { useContext } from "react";
import {
  ProceedProvider,
  useProceedContext,
} from "../../_helper/Proceed/ProceedProvider";
import UserContext from "../../_helper/User";
import TableContext from "../../_helper/TableHolder";
import LocationContext from "../../_helper/Location";
import { Navigate } from "react-router";
// import { useOrderContext } from "./OrderProcess";

const OrderSummary = ({ onProceed }) => {
  const [proceedDateTime, setProceedDateTime] = useState(null);
  const [filteredposid, setFilteredposId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { totalPrice, currency } = useSelector(selectTotalPriceWithCurrency);
  const cartItems = useSelector(selectItemsInCart);
  const [cartitemset, setItemsInCart] = useState({});
  const [cartitemsettotal, setTotalPrice] = useState([]);
  const [showPopupconfirm, setShowPopupconfirm] = useState(false);
  const [tableData, setTableData] = useState({});
  const discount = (totalPrice * 0.1) / 100;
  const deliveryCharges = (totalPrice * 0.05) / 100;
  const totalAmt = totalPrice / 100 + deliveryCharges - discount;
  const dispatch = useDispatch();
  const [posMasterStatus, setPosMasterStatus] = useState([]);
  const { addposData, posData } = useContext(PosMasterContext);
  const userData = useContext(UserContext);
  const { waiterintable } = useContext(TableContext);
  const { waiterin } = useContext(LocationContext);
  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  console.log(waiterintable);
  console.log(waiterin);
  console.log(userData);
  console.log(tableData);

  console.log(cartitemset);

  // useEffect to fetch data if addTableData did not fetch any data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if tableData is already populated
        if (!tableData || Object.keys(tableData).length === 0) {
          const requestData = {
            orgId: orgId,
            locationId: locationId,
          };

          const reqInstance = axios.create({
            baseURL: backendURL,
          });

          const tableResponse = await reqInstance.post(
            GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
            requestData
          );

          if (tableResponse) {
            const tableResult = tableResponse.data;

            // Only update state if there is new data
            if (!tableResult.hasError) {
              setTableData(tableResult.tableDetail);
              console.log(tableResult);
            } else {
              console.error(
                "Error in category API response:",
                tableResult.errorMessage
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [orgId, locationId]); // Include orgId and locationId as dependencies

  useEffect(() => {
    // Filter the table names based on the selected tableId
    const filteredNames = Array.isArray(tableData)
      ? tableData
          .filter((table) => parseInt(table.saPosTableId) === parseInt(tableId))
          .map((filteredTable) => filteredTable.saPosMasterId)
      : [];

    // Set the filtered names to the state
    setFilteredposId(filteredNames.length > 0 ? filteredNames[0] : null);
    console.log(filteredNames);
  }, [tableId, tableData]);

  // useEffect(() => {
  //   console.log("Table ID:", tableId);
  //   console.log("Original Table Data:", tableData);

  //   // Filter the table names based on the selected tableId
  //   const filteredNames = Array.isArray(tableData)
  //     ? tableData
  //         .filter((table) => {
  //           console.log("Filtering:", table);
  //           return parseInt(table.saPosTableId) === parseInt(tableId);
  //         })
  //         .map((filteredTable) => {
  //           console.log("Mapping:", filteredTable);
  //           return filteredTable.saPosMasterId;
  //         })
  //     : [];

  //   console.log("Filtered Names:", filteredNames);

  //   // Set the filtered names to the state
  //   setFilteredposId(filteredNames);
  //   console.log("FilteredposId State:", filteredNames);
  // }, [tableId, tableData]);

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.id,
    waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.id);
    }
  }, [
    waiterin.id,
    waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  console.log(waiterintable.id);
  console.log(totalPrice);
  console.log(currency);

  useEffect(() => {
    console.log("cart: ", cartItems);
    console.log("waiterintable.waiterintable.id: ", waiterintable.id);

    const filteredCartItems = cartItems.filter(
      (item) =>
        item.item.tableId === parseInt(waiterintable.id) ||
        item.item.tableId === parseInt(userData.userData.tableId)
    );

    // Map totalPrice to filteredCartItems
    // Calculate total price
    const totalPrice = filteredCartItems.reduce(
      (total, item) => total + item.item.price * item.quantity,
      0
    );

    // Set total price as state
    setTotalPrice(totalPrice);
    console.log(filteredCartItems);
    localStorage.setItem(
      `carthistory${waiterintable.id}`,
      JSON.stringify(filteredCartItems)
    );
    setItemsInCart(filteredCartItems);
  }, [cartItems, waiterintable.id]);
  localStorage.setItem(
    `cartquantity${waiterintable.id}`,
    JSON.stringify(cartitemset.length)
  );

  useEffect(() => {
    console.log(userData);
    console.log("waitertable:", waiterintable);
    console.log("waiterinloc:", waiterin);
    console.log(waiterintable.posMasterIdTable);
  }, [userData, waiterintable, waiterin]);

  const handleProceedClick = async () => {
    setShowPopup(true);
    onProceed(true);
    try {
      // Fetch the data needed for the request
      const requestData = {
        orgId: orgId,
        locationId: locationId,
        tableId: tableId,
        posMasterId: filteredposid,
        bpartnerId: userData.userData.servicemanSaBpartnerId,
        subTotal: cartitemsettotal, // Assuming totalAmt is calculated correctly
        items: cartitemset.map((cartItem) => ({
          saItemMasterId: cartItem.item.saItemMasterId,
          sellingPice: cartItem.item.price,
          saCategoryId: cartItem.item.saCategoryId,
          saPriceLevelId: cartItem.item.saPriceLevelId,
          saPosOrderTicketTypeId: cartItem.item.saPosOrderTicketTypeId,
          qty: cartItem.quantity,
          remark: cartItem.notes || "", // Add notes if available
        })),
      };
      const reqInstance = axios.create({
        baseURL: backendURL,
      });
      // Fetch additional data using the created instance
      const tableResponse = await reqInstance.post(
        "/proceedQRposorderForPO",
        requestData
      );
      // Check if the request was successful
      if (!tableResponse.data.hasError) {
        addposData(tableResponse.data);

        // Handle the success case (you can add more logic here)
        console.log("Table data fetched successfully:", tableResponse.data);
        // Continue with the rest of your code
      } else {
        // Handle the error case
        console.error(
          "Error fetching table data:",
          tableResponse.data.errorMsg
        );
        // You might want to show an alert or perform other actions for error handling
      }
      // The rest of your existing code...
    } catch (error) {
      // Handle any other errors that may occur during the request
      console.error("Error fetching table data:", error.message);
      // You might want to show an alert or perform other actions for error handling
    }
  };

  const handleClosePopup = () => {
    dispatch(clearCart(true));

    // dispatch(storeCartHistory(true));
    setShowPopup(false);
    setOrderPlaced(true);
    // dispatch(setShowItemCount(true));
  };

  const openpopup = () => {
    setShowPopupconfirm(true);
  };

  const handleyes = () => {
    // dispatch(clearCache(true));
    // navigate(`/waiter-login`);
    // setItemsInCart();
    handleProceedClick();
    setShowPopupconfirm(false);
    // window.location.reload();
  };
  const handleno = () => {
    // dispatch(clearCart(true));
    setShowPopupconfirm(false);
  };

  return (
    // <ProceedProvider>
    <div>
      <div className="basis-6/12 h-fit sticky top-40 p-8 rounded-md border shadow-md my-8 md:m-0">
        <h2 className="text-xl font-bold border-b pb-4">Order Summary</h2>

        {/* order details */}
        <div className="py-4 text-lg space-y-4 border-b">
          <div className="flex justify-between items-center font-semibold">
            <p className="font-normal">Price ({cartitemset.length} items)</p>
            <p>
              {currency}&nbsp;{parseFloat(cartitemsettotal).toFixed(2)}
            </p>
          </div>
          {/* <div className="flex justify-between items-center font-semibold">
            <p className="font-normal">Discount (10%)</p>
            <p> - ₹ {parseFloat(discount).toFixed(2)}</p>
          </div> */}
          {/* <div className="flex justify-between items-center font-semibold">
            <p className="font-normal">Delivery charges (5%)</p>
            <p>+ ₹ {parseFloat(deliveryCharges).toFixed(2)}</p>
          </div> */}

          {/* <p className="text-sm my-2">
            You'll save ₹{parseFloat(discount).toFixed(2)} on this order 🎉
          </p> */}
        </div>

        <div className="py-4 border-b">
          <div className="md:flex justify-between items-center font-bold text-lg md:text-2xl">
            <h1>Total Amount </h1>
            <h1 className="text-orange-500">
              &nbsp;{currency}&nbsp;
              {parseFloat(cartitemsettotal).toFixed(2)}
            </h1>
          </div>
        </div>
        {/* Proceed Button */}
        <button
          className="w-full block mt-4 uppercase font-bold text-lg proceed-btn text-white text-center p-4 rounded-md"
          onClick={() => openpopup()}
        >
          Proceed
        </button>
      </div>
      {/* Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h1 className="fs-2">Order Placed Successfully!</h1>
            <p className="fs-6 pb-3"> Your Order is bieng prepared. 🎉 </p>
            <button className="btnpop" onClick={handleClosePopup}>
              OK
            </button>
          </div>
        </div>
      )}{" "}
      {/* Popup */}{" "}
      {showPopupconfirm && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h1 className="fs-4 text-warning">
              Please Confirm The Order Before Proceed
            </h1>
            {/* <p className="fs-6 pb-3">
                 
                </p> */}

            <ul
              className="p-2 d-flex inline"
              style={{
                listStyleType: "none",
                // margin: 0,
                // padding: 0,
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <li>
                <button
                  className=" btnpop  "
                  onClick={handleno}
                  style={{ minWidth: "max-content" }}
                >
                  Add More
                </button>
              </li>{" "}
              or
              <li>
                <button
                  className=" btnpop "
                  onClick={handleyes}
                  style={{ minWidth: "max-content" }}
                >
                  Confirm
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
    // </ProceedProvider>
  );
};

export default OrderSummary;
