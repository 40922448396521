import React, { Fragment, useContext, useEffect, useState } from "react";
import { H3, LI, P, UL } from "../../../../AbstractElements";
// import { Brand, Availability, AddToCart, BuyNow } from '../../../../';
import ProductContext from "../../../../_helper/Ecommerce/Product";
import WishtListContext from "../../../../_helper/Ecommerce/Wishlist";
// import SocialIcons from './SocialIcons';
// import StarRatings from './StarRating';
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CartContext from "../../../../_helper/Ecommerce/Cart";
import CustomizerContext from "../../../../_helper/Customizer";
import { Brand, Availability, AddToCart, BuyNow } from "../../../../Constant";
import { defaultPath } from "../../../../utils/constants";
import { Printer } from "react-feather";
import { selectItemsInCart } from "../../../../cart/cartSlice";
import { useSelector } from "react-redux";

const BillRequest = ({ data }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const [showLoader, setShowLoader] = useState(false);
  const itemsInCart = useSelector(selectItemsInCart);
  const { productItem, symbol } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);
  const [productss, setProductss] = useState("");
  const path = window.location.pathname.split("/").pop();

  useEffect(() => {
    productItem.map((item) => {
      if (item.id === path) {
        setProductss(item);
      }
      return null;
    });
  });

  const handleRequestBillClick = () => {
    setShowLoader(true);

    // Simulate loading with a timeout
    setTimeout(() => {
      setShowLoader(false);
      // Add logic for handling the request bill action
    }, 10000);
  };

  const quantity = 1;
  const history = useNavigate();
  const { addToWishList } = useContext(WishtListContext);

  const singleItem = productItem;
  const addWishList = (product) => {
    addToWishList(product);
    history(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist/${layoutURL}`);
  };

  const AddToCarts = (item, quantity) => {
    addToCart(item, quantity);
  };

  const buyProduct = (item, quantity) => {
    addToCart(item, quantity);
  };

  const navigateToInterfaceOne = () => {
    // Use the <a> tag with the href attribute to navigate
    window.location.href = {};
  };

  console.log(data);

  return (
    <Fragment>
      <Col xxl="12" className="box-col-12 order-xxl-0 order-1">
        <Row>
          <Card>
            <div className="product-page-details ">
              <H3 className="text-success fs-2">{"Request Bill"}</H3>
            </div>
            <CardBody>
              <hr />
              <div className="p-t-10 p-b-10">
                <table className="product-page-width fs-6 text-muted">
                  <tbody>
                    <tr>
                      <td>
                        <b>{"Table"} &nbsp;&nbsp;&nbsp;:</b>
                      </td>
                      <td>{data.table}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{"Status"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                      </td>
                      <td className="txt-success fs-6">
                        {"Your Cart is Empty"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{"Waiter"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                      </td>
                      <td>{data.servicemanSaBpartnerName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{"Total"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                      </td>
                      <td>{data.subtotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              {/* <div className="d-flex justify-content-center p-t-20">
                <Link
                  to={`/sample-page/interface-one/${defaultPath.orgId}/${defaultPath.locationId}/${defaultPath.tableId}`}
                >
                  <Button
                    color="primary"
                    className="m-r-10 m-t-10"
                    // onClick={navigateToInterfaceOne}
                  >
                    <i className="d-flex justify-content-center fa fa-shopping-basket me-1 "></i>
                    {"View Menu"}
                  </Button>
                </Link>
              </div> */}
            </CardBody>
          </Card>
        </Row>
      </Col>
    </Fragment>
  );
};
export default BillRequest;

//  <div className="d-flex justify-content-center p-t-20">
//    <Link
//      onClick={handleRequestBillClick}
//      to=""
//      className="text-decoration-none"
//    >
//      <div className="btn cart-btn hover:bg-orange-500 hover:text-white inline flex p-2">
//        <div className="ps-3 align-self-center"> Request Bill</div>
//        <div className="ps-3 align-self-center">
//          <Printer className="16px" />
//        </div>
//      </div>{" "}
//    </Link>
//    {/* </div>
//             <div className="d-flex justify-content-center p-t-20"> */}
//    <Link
//      to={`/sample-page/interface-one/${defaultPath.orgId}/${defaultPath.locationId}/${defaultPath.tableId}`}
//    >
//      <Button
//        color="primary"
//        className="m-r-10 m-t-10"
//        // onClick={navigateToInterfaceOne}
//      >
//        <i className="d-flex justify-content-center fa fa-shopping-basket me-1 "></i>
//        {"View Menu"}
//      </Button>
//    </Link>
//  </div>;

// <Row>
//   <Card>
//     <div className="product-page-details ">
//       <H3 className="text-success fs-2">{"Request Bill"}</H3>
//     </div>
//     <CardBody>
//       <hr />
//       <div className="p-t-10 p-b-10">
//         <table className="product-page-width fs-6 text-muted">
//           <tbody>
//             <tr>
//               <td>
//                 <b>{"Table"} &nbsp;&nbsp;&nbsp;:</b>
//               </td>
//               <td>{data.title}</td>
//             </tr>
//             <tr>
//               <td>
//                 <b>{"Status"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
//               </td>
//               <td className="txt-success fs-6">{itemsInCart.length}</td>
//             </tr>
//             <tr>
//               <td>
//                 <b>{"Waiter"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
//               </td>
//               <td>{"name"}</td>
//             </tr>
//             <tr>
//               <td>
//                 <b>{"Total"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
//               </td>
//               <td>{data.subtotal}</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <hr />
//       {/* <div className="d-flex justify-content-center p-t-20">
//               <Link
//                 to={`/sample-page/interface-one/${defaultPath.orgId}/${defaultPath.locationId}/${defaultPath.tableId}`}
//               >
//                 <Button
//                   color="primary"
//                   className="m-r-10 m-t-10"
//                   // onClick={navigateToInterfaceOne}
//                 >
//                   <i className="d-flex justify-content-center fa fa-shopping-basket me-1 "></i>
//                   {"View Menu"}
//                 </Button>
//               </Link>
//             </div>{" "} */}
//     </CardBody>
//   </Card>
// </Row>;
