// import React, { Fragment, useState, useEffect } from "react";
// import { Form, FormGroup, Input, Label } from "reactstrap";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";

// import { toast } from "react-toastify";
// import { useContext } from "react";
// import UserContext from "../../../_helper/User";
// import { Btn, P } from "../../../AbstractElements";
// import { CHECK_LOGIN_DETAIL_FOR_PO } from "../../../utils/constants";
// import { Jwt_token, backendURL, hostedURL } from "../../../Config/Config";

// const LoginTab = () => {
//   const [togglePassword, setTogglePassword] = useState(false);
//   const [orgCode, setOrgCode] = useState("");
//   const [password, setPassword] = useState("");
//   const [loginResult, setLoginResult] = useState(null);
//   const navigate = useNavigate();
//   const { addUserData } = useContext(UserContext);

//   const loginWithJwt = async (password, orgCode) => {
//     const reqBody = {
//       password: password,
//       orgCode: orgCode,
//     };

//     const reqInstance = axios.create({
//       baseURL: backendURL,
//     });

//     try {
//       const response_data = await reqInstance.post(
//         CHECK_LOGIN_DETAIL_FOR_PO,
//         reqBody
//       );

//       if (response_data) {
//         const result = response_data.data;
//         localStorage.setItem("token", Jwt_token);

//         addUserData(result);
//         console.log("Login Result:", result); // Log the result for debugging

//         if (!result.hasError) {
//           if (result.servicemanSaBpartnerId && result.saOrgId) {
//             console.log("Login successful condition met!");
//             navigate(`/waiter-area`);
//             setLoginResult(response_data);
//             // return { success: true, data: result };
//           } else {
//             console.log("Login failed condition not met!");
//             // return { success: false, error: "Login failed" };
//           }
//         } else {
//           console.log("Login failed due to error:", result.errorMsg);
//           // return { success: false, error: result.errorMsg || "Login failed" };
//         }
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       // return { success: false, error: "Network error or other error" };
//     }
//   };

//   // const handleLogin = async () => {
//   //   // const result = await loginWithJwt(password, orgCode);

//   //   if (result.success) {
//   //     console.log("Login successful!");
//   //     // If login is successful, navigate to the next page
//   //     window.location.href = `${hostedURL}waiter-area`; // Replace '/nextPage' with the actual path you want to navigate to
//   //   }

//   //   setLoginResult(result);
//   // };

//   // const handleLogin = async () => {
//   //   const result = await loginWithJwt(password, orgCode);
//   //   const navigate = useNavigate(); // Add this line

//   //   if (result.success) {
//   //     console.log("Login successful!");
//   //     // If login is successful, navigate to the next page
//   //     navigate("/waiter-area"); // Use the navigate function from the hook
//   //   }

//   //   setLoginResult(result);
//   // };

//   return (
//     <Fragment>
//       <div className="login-card">
//         <div>
//           <div className="fs-1 text-orange-500 ">{/* Logo code here */}</div>

//           <div className="login-main">
//             <Form className="theme-form login-form">
//               <h1 className="fs-1 text-orange-500">Waiter CMS</h1>
//               <P>Enter Waiter Initials</P>
//               <FormGroup>
//                 <Label className="col-form-label m-0">Pass Code</Label>
//                 <div className="position-relative">
//                   <Input
//                     className="form-control"
//                     type={togglePassword ? "text" : "password"}
//                     name="passCode"
//                     required
//                     placeholder="Pass Code"
//                     // onChange={(e) => setPassword(e.target.value)}
//                   />
//                   <div
//                     className="show-hide"
//                     onClick={() => setTogglePassword(!togglePassword)}
//                   >
//                     <span
//                       className={
//                         togglePassword ? "text-warning" : "show text-warning"
//                       }
//                     ></span>
//                   </div>
//                 </div>
//               </FormGroup>
//               <FormGroup className="position-relative">
//                 <Label className="col-form-label m-0">Hotel Code</Label>
//                 <div className="position-relative">
//                   <Input
//                     className="form-control"
//                     type={togglePassword ? "text" : "password"}
//                     name="hotelCode"
//                     required
//                     placeholder="Hotel Code"
//                     // onChange={(e) => setOrgCode(e.target.value)}
//                   />
//                 </div>
//               </FormGroup>
//               <FormGroup className="position-relative ">
//                 <div className="checkbox ">
//                   <Input id="checkbox1" type="checkbox" />
//                   <Label className="text-muted" for="checkbox1">
//                     Remember password
//                   </Label>
//                 </div>
//               </FormGroup>
//               <FormGroup>
//                 <Btn
//                   attrBtn={{
//                     className: "d-block w-100 mt-2",
//                     color: "success",
//                     type: "submit",
//                   }}
//                 >
//                   Log in
//                 </Btn>
//                 {loginResult && (
//                   <div>
//                     {loginResult.success ? (
//                       <p>Login successful! Redirecting to the next page...</p>
//                     ) : (
//                       <p>Login failed. {loginResult.error}</p>
//                     )}
//                   </div>
//                 )}
//               </FormGroup>
//             </Form>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default LoginTab;

import React, { Fragment, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Btn, P } from "../../../AbstractElements";
import { CHECK_LOGIN_DETAIL_FOR_PO } from "../../../utils/constants";
import { Jwt_token, backendURL } from "../../../Config/Config";
import { useContext } from "react";
import UserContext from "../../../_helper/User";

const LoginTab = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [orgCode, setOrgCode] = useState("");
  const [password, setPassword] = useState("");
  const [loginResult, setLoginResult] = useState(null);
  const navigate = useNavigate();

  const { addUserData } = useContext(UserContext);
  const loginWithJwt = async (password, orgCode) => {
    const reqBody = {
      password: password,
      orgCode: orgCode,
    };

    const reqInstance = axios.create({
      baseURL: backendURL,
    });

    try {
      const response_data = await reqInstance.post(
        CHECK_LOGIN_DETAIL_FOR_PO,
        reqBody
      );

      if (response_data) {
        const result = response_data.data;
        addUserData(result);
        if (
          !result.hasError &&
          result.servicemanSaBpartnerId &&
          result.saOrgId
        ) {
          console.log("Login successful!");
          localStorage.setItem("token", Jwt_token);
          setLoginResult({ success: true, data: result });
          navigate(`/waiter-area`);
        } else {
          console.log("Login failed!");
          setLoginResult({ success: false, error: "Login failed" });
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginResult({ success: false, error: "Network error or other error" });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission and page reload
    await loginWithJwt(password, orgCode);
  };

  return (
    <Fragment>
      <div className="login-card">
        <div>
          <div className="fs-1 text-orange-500 ">{/* Logo code here */}</div>

          <div className="login-main">
            <Form className="theme-form login-form" onSubmit={handleLogin}>
              <h1 className="fs-1 text-orange-500">Waiter CMS</h1>
              <P>Enter Waiter Initials</P>
              <FormGroup>
                <Label className="col-form-label m-0">Pass Code</Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    name="passCode"
                    required
                    placeholder="Pass Code"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span
                      className={
                        togglePassword ? "text-warning" : "show text-warning"
                      }
                    ></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0">Hotel Code</Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type="text" // Assuming it should be a text input for the hotel code
                    name="hotelCode"
                    required
                    placeholder="Hotel Code"
                    onChange={(e) => setOrgCode(e.target.value)}
                  />
                </div>
              </FormGroup>
              <FormGroup className="position-relative ">
                <div className="checkbox ">
                  <Input id="checkbox1" type="checkbox" />
                  <Label className="text-muted" for="checkbox1">
                    Remember password
                  </Label>
                </div>
              </FormGroup>
              <FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100 mt-2",
                    color: "success",
                    type: "submit",
                  }}
                >
                  Log in
                </Btn>
                {loginResult && (
                  <div>
                    {loginResult.success ? (
                      <p>Login successful! Redirecting to the next page...</p>
                    ) : (
                      <p>{loginResult.error}</p>
                    )}
                  </div>
                )}
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginTab;
