const config = require('./AppConfig.json');

// Auth0
export const auth0 = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientID,
  redirectUri: config.auth0.redirectUri,
};

// Jwt
export const Jwt_token = config.jwt_token;

//
export const backendURL = config.backendURL;
export const adminBackendURL = config.adminBackendURL;
export const hostedURL = config.hostedURL;
export const imageFileURL = config.fileServerURL;
export const saproUrlQr = config.QRURL;
export const MAX_ASSET_IMAGE_SIZE = config.imageMaxSize.assetImage;