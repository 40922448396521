import React from "react";
import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  HashRouter,
} from "react-router-dom";
import {
  configureFakeBackend,
  authHeader,
  handleResponse,
} from "../Services/fack.backend";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import { authRoutes } from "./AuthRoutes";
import InterfaceOne from "../../src/Components/Pages/PageLayout/InterfaceOne";
import SamplePage from "../Components/Pages/PageLayout/SimplePage";
import Restaurant from "../Components/Pages/PageLayout/Restaurant";
import TopNavBar from "../Layout/TopNavBar";
// import CartItemList from "../Components/Pages/PageLayout/CartItemList";
import Cart from "../Components/Pages/PageLayout/Cart";
import WaiterLoginPage from "../Components/Pages/PageLayout/WaiterLogin";
import Waiter from "../Components/Pages/PageLayout/Waiter/Waiter";
import { store, persistor } from "../store/store";
import { Provider } from "react-redux";
import Logins from "../Auth/Signin";
import { hostedURL } from "../Config/Config";
import WaiterLocation from "../Components/Pages/PageLayout/Waiter/WaiterLocation";
import { PersistGate } from "redux-persist/integration/react";
// import Waiter from "../Components/Pages/PageLayout/Waiter";
// import ArrowLeftComponent from "../Layout/TopNavBar/StyledBack";

// setup fake backend
configureFakeBackend();
const Routers = () => {
  const currentUser = useState(false)[0];
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    let abortController = new AbortController();
    const requestOptions = { method: "GET", headers: authHeader() };
    fetch("/users", requestOptions).then(handleResponse);

    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <HashRouter basename={"/"}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path={``}
                element={<Navigate to={`waiter-login`} />}
              />
              <Route
                exact
                path={`/`}
                element={<Navigate to={`/waiter-login`} />}
              />
              {/* <Route
                    exact
                    path={`/`}
                    element={<Navigate to={`interface-one/restaurants/`} />}
                  /> */}

              <Route path={`/*`} element={<LayoutRoutes />} />
              {/* </Route>{" "} */}
              {/* <Route path="/splash-screen" component={<SamplePage />} /> */}
              {/* <Route path="/interface-one" component={<InterfaceOne />} /> */}
              {/* <Route path="/restaurants" component={<Restaurant />} /> */}
              {/* <Route path="cart" component={<Cart />} /> */}
              {/* <Route path="login" component={<WaiterLoginPage />} /> */}
              {/* <Route path="waiter" component={<Waiter />} /> */}
              {/* <Route path="/restaurant/" component={<Restaurant />} /> */}
              {/* <Route
              path={`${process.env.PUBLIC_URL}/callback`}
              render={() => <Callback />}
            /> */}
              <Route
                exact
                path={`${hostedURL}/waiter-login`}
                element={<Logins />}
              />
              <Route
                exact
                path={`${hostedURL}/waiter-area`}
                element={<WaiterLocation />}
              />
              <Route
                exact
                path={`${process.env.GET_RESTAURANTS_URL}/waiter`}
                element={<Waiter />}
              />
              {/* {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))} */}
            </Routes>
          </Suspense>
        </PersistGate>
      </Provider>
    </HashRouter>
  );
};

export default Routers;
