import React, { Fragment, useContext, useEffect, useState } from "react";
import { Btn, H4, H6, Image, LI, P, UL } from "../../../../AbstractElements";
import {
  Quantity,
  AddToCart,
  ViewDetails,
  ProductSizeArray,
} from "../../../../Constant";
import CartContext from "../../../../_helper/Ecommerce/Cart";
import ProductContext from "../../../../_helper/Ecommerce/Product";
import {
  Modal,
  Col,
  InputGroup,
  InputGroupText,
  Input,
  ModalBody,
  Row,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CustomizerContext from "../../../../_helper/Customizer";
import Reservation from "./Reserve";
import { X } from "react-feather";
import ImageBackground from "../SplashScreen";
import ProductDetails from "./ProductDetails";
import OngoingDetails from "./ongoingDetails";
import BillRequest from "./BillRequest";
import PaperNote from "./PaperNote";

// import Reservation from "./Reservation";
// import Reservation from "./Reservation";
// import Reservation from "./Reservation";
// import ProductModal from "./ProductModal";

const ProductModal = (props) => {
  const [open, setOpen] = useState(props.value);
  // eslint-disable-next-line
  const [stock, setStock] = useState("");
  const { addToCart } = useContext(CartContext);
  const { layoutURL } = useContext(CustomizerContext);
  const { productItem, symbol } = useContext(ProductContext);
  const [quantity, setQuantity] = useState(1);
  const [singleProduct, setSingleProduct] = useState([]);

  useEffect(() => {
    setOpen(props.value);
  }, [props.value]);

  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value));
  };
  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1);
    } else {
      setStock("Out of Stock !");
    }
  };
  const minusQty = () => {
    if (quantity > 1) {
      setStock("InStock");
      setQuantity(quantity - 1);
    }
  };
  const onCloseModal = () => {
    setOpen(false);
    props.setOpenModal(false);
  };
  const history = useNavigate();
  const AddToCarts = (item, quantity) => {
    addToCart(item, quantity);
    history(`${process.env.PUBLIC_URL}/app/ecommerce/cart/${layoutURL}`);
  };

  // const navigateToInterfaceOne = () => {
  //   // Use the <a> tag with the href attribute to navigate
  //   window.location.href = "/sample-page/interface-one";
  // };

  const renderModalContent = () => {
    console.log("renderModalContent - props.dataid:", props.dataid);
    switch (props.dataid) {
      case 1:
        return <ProductDetails data={props.data} />;
      case 2:
        // return <OngoingDetails data={props.data} />;
        return <OngoingDetails data={props.data} />;
      case 3:
        return <BillRequest data={props.data} />;
      // default:
      //   return null;
    }
  };

  return (
    <Fragment>
      <Modal
        className="d-flex justify-content-center   modal-dialog-centered product-modal modal-body"
        isOpen={open}
      >
        <div className="d-flex justify-content-end">
          <Btn
            attrBtn={{
              color: "Black",
              className: "btn-close p-4",
              onClick: onCloseModal,
              type: "button",
              "data-bs-dismiss": "modal", // Fix typo in your code: "databsdismiss" should be "data-bs-dismiss"
              "aria-label": "Close",
            }}
          >
            <X />
          </Btn>
        </div>

        <div className="d-flex justify-content-center">
          <div className="modal-title">
            <div className="product-box row">
              <Col className="text-start">
                <div className="fs-4 p-2 text-success">
                  {renderModalContent()}
                </div>
              </Col>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    </Fragment>
  );
};
export default ProductModal;
