import { useSelector, useDispatch } from "react-redux";

import cartSlice, {
  addNotesToItem,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeFromCart,
  selectItemsInCart,
  setItemsInCart,
  storeCartHistory,
} from "../../cart/cartSlice";
import { CDN_URL, logindefault } from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import { Edit2, Edit3, Trash2, X } from "react-feather";
import { Card, Input } from "reactstrap";
import { Fragment, useContext, useState } from "react";
import { Apply } from "../../Constant";
import { Btn, LI, UL } from "../../AbstractElements";
import TableContext from "../../_helper/TableHolder";
import { useEffect } from "react";
import UserContext from "../../_helper/User";
import defaultImage from "../../assets/images/default/def.png";
import { useParams } from "react-router";
// import { useProceedContext } from "../../_helper/Proceed/ProceedProvider";

const CartItemList = () => {
  const cartItems = useSelector(selectItemsInCart);
  const dispatch = useDispatch();
  const [showNotesInput, setShowNotesInput] = useState(false);
  const [notes, setNotes] = useState({});
  const [cartitemset, setItemsInCart] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const [noteApplied, setNoteApplied] = useState(false);
  const waiterintable = useContext(TableContext);
  const userData = useContext(UserContext);
  let tableId = waiterintable.waiterintable.id;
  console.log(waiterintable.waiterintable.id);

  console.log({});
  console.log(userData);

  const removeItem = (id) => dispatch(removeFromCart({ id }));
  const decreaseQuantity = (id) => dispatch(decreaseItemQuantity({ id }));
  const increaseQuantity = (id) => dispatch(increaseItemQuantity({ id }));

  const toggleNotesInput = (id) => {
    setActiveItemId(id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    // setActiveItemId(null);
  };

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const applyNotes = (item) => {
    const notesArray = notes[item?.item?.id] || []; // Initialize as an array
    const notesString = notesArray.join("\n");
    dispatch(addNotesToItem({ id: item?.item?.id, notes: notesString }));
    saveNotesToLocalStorage(item?.item?.id, notesArray);
    handleClosePopup();
  };

  const saveNotesToLocalStorage = (itemId, notesArray) => {
    const storedNotes = { ...notes };
    storedNotes[itemId] = notesArray;
    localStorage.setItem("cartNotes", JSON.stringify(storedNotes));
  };

  useEffect(() => {
    const storedNotesString = localStorage.getItem("cartNotes");
    const storedNotes = storedNotesString ? JSON.parse(storedNotesString) : {};
    const filteredCartItems = cartItems.map((item) => ({
      ...item,
      notes: storedNotes[item?.item?.id] || "",
    }));
    setItemsInCart(filteredCartItems);
    setNotes(storedNotes);
    setNoteApplied(storedNotes);
  }, [cartItems]);

  useEffect(() => {
    console.log("cart: ", cartItems);
    console.log(
      "waiterintable.waiterintable.id: ",
      waiterintable.waiterintable.id
    );
    const filteredCartItems = cartItems.filter(
      (item) =>
        item.item.tableId === parseInt(waiterintable.waiterintable.id) ||
        item.item.tableId === parseInt(userData.userData.tableId)
    );

    console.log(filteredCartItems);
    setItemsInCart(filteredCartItems);
  }, [cartItems, waiterintable, userData]);

  if (!cartitemset || cartitemset.length === 0) {
    return (
      <div className="flex grow min-h-[60vh] justify-center items-center">
        <p>Your cart is empty!</p>
      </div>
    );
  }

  // localStorage.setItem(`cartdata${tableId}`, JSON.stringify(cartitemset));

  // console.log(filteredCarts);

  const removeNote = (noteId) => {
    // Implement your logic to remove the note
    // For example, you can update the state to remove the note
    setNotes((prevNotes) => {
      const newNotes = { ...prevNotes };
      delete newNotes[noteId];
      return newNotes;
    });
  };

  return (
    <Fragment>
      <ul className="basis-8/12">
        {cartitemset &&
          cartitemset.map((item) => (
            <li
              key={`${item?.item?.id}-${item?.tableId}`}
              className="flex gap-4 justify-between max-w-[600px] my-4"
            >
              <div className="bg-gray-50 d-flex p-2 basis-5/12 ">
                <div className="basis-8/12">
                  <img
                    className="w-full h-full md:h-auto object-cover block rounded-md aspect-square"
                    src={
                      item && item.item && item.item.imagePath
                        ? `${backendURL}${item.item.imagePath}`
                        : defaultImage
                    }
                    alt=""
                  />
                </div>{" "}
                <div className="basis-4/12 flex flex text-left justify-content-center px-1">
                  <button
                    onClick={() => removeItem(item?.item?.id)}
                    className=" border-orange-500 text-xs font-semibold text-orange-500  rounded-md "
                  >
                    <Trash2 />
                  </button>
                </div>
              </div>
              <div className="basis-9/12">
                <p className="text-lg font-semibold">{item?.item?.name}</p>

                <p className=" md:block">
                  {item?.item?.description?.length > 50
                    ? item?.item?.description.slice(0, 50) + "..."
                    : item?.item?.description}
                </p>
                <p className="my-2 space-x-1">
                  <span className="font-semibold">
                    {item.item.currency}&nbsp;
                    {parseFloat(
                      item.quantity * parseFloat(item?.item?.price.toFixed(2))
                    )}
                  </span>
                  <span className=" font-normal">
                    ({item?.item?.price} × {item?.quantity})
                  </span>
                </p>

                {/* actions */}
                <div className="flex justify-between items-center mt-2">
                  <div className="flex items-center">
                    <button
                      onClick={() => decreaseQuantity(item?.item?.id)}
                      disabled={item.quantity === 1}
                      className={
                        "bg-orange-500 disabled:bg-orange-500/50 disabled:cursor-not-allowed text-white font-bold w-8 h-8 rounded-md"
                      }
                    >
                      -
                    </button>
                    <p className="font-bold w-8 h-8 flex justify-center items-center">
                      {item.quantity}
                    </p>
                    <button
                      onClick={() => increaseQuantity(item?.item?.id)}
                      className="bg-orange-500 text-white font-bold w-8 h-8 rounded-md"
                    >
                      +
                    </button>
                  </div>

                  <button
                    onClick={() => toggleNotesInput(item?.item?.id)}
                    className="bg-orange-500 text-white w-8 h-8 rounded-md flex justify-center items-center"
                  >
                    <Edit2 style={{ width: 16, height: 16 }} />
                  </button>

                  {showPopup && activeItemId === item?.item?.id && (
                    <div className="popup-overlay">
                      <div className="popup-container">
                        <X
                          width={"22px"}
                          height={"22px"}
                          color="black"
                          className="p-1 "
                          onClick={handleClosePopup}
                        />
                        <div className="input-group p-3">
                          <input
                            className="form-control me-6"
                            type="text"
                            placeholder="Add notes"
                            value={notes[item?.item?.id]?.join("\n") || ""}
                            onChange={(e) =>
                              setNotes((prevNotes) => ({
                                ...prevNotes,
                                [item?.item?.id]: e.target.value.split("\n"),
                              }))
                            }
                          />
                          <button
                            onClick={() => {
                              applyNotes(item);
                              setNoteApplied(true);
                            }}
                            className="btn btn-warning hover:btn-success text-white rounded-md"
                          >
                            Apply
                          </button>
                        </div>
                        <div className="d-flex align-item-center justify-content-center">
                          {Object.keys(notes).map((noteId) => (
                            <div key={noteId} className="note-container p-2">
                              {noteApplied && (
                                <Btn
                                  attrBtn={{
                                    className:
                                      "d-flex btn btn-pill btn-p-2 btn-air-warning btn-square btn-xs inline gap-4 p-1",
                                    color: "secondary",
                                    onClick: () => removeNote(noteId),
                                  }}
                                >
                                  {notes[noteId]}
                                  <X width={"14px"} height={"14px"} />
                                </Btn>
                              )}
                            </div>
                          ))}
                        </div>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  )}

                  {/* <button
                  onClick={() => removeItem(item?.item?.id)}
                  className=" border-orange-500 text-xs font-semibold text-orange-500  rounded-md   "
                >
                  <Trash2 />
                </button> */}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </Fragment>
  );
};

export default CartItemList;
