import React, { useState, useEffect } from "react";
// import Axios from "axios";
// import { Button, Container } from "reactstrap";
import "../../../../src/assets/scss/themes/_custom.scss";
// import SplashScreen from "./SplashScreen";
// import ImageBackground from "./SplashScreen";
//import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
// import TopNavBar from "../../../../src/Layout/TopNavBar";
// import UserHeader from "../../../Layout/Header/RightHeader/UserHeader";
import Header from "../../../../src/Layout/Header/index";
// import ImageSlider from "../../../Layout/ImageSlider";
// import ImageCarousel from "../../../Layout/ImageSlider";
import { GET_RESTAURANTS_URL } from "../../../../src/utils/constants";
import useRestaurants from "../../../../src/Hooks/useRestaurants";
//import SlickSlider from "../../../Layout/Carousel/SlickSlider";
import BannerList from "../../../Layout/ImageSlider";
// import FoodList from "../../../Layout/FoodList";
import RestaurantList from "../../../Layout/Restaurant/RestaurantList";
import { backendURL } from "../../../Config/Config";
import ToggleCart from "./ToggleCart";
import UserContext from "../../../_helper/User";
import { useContext } from "react";
import { useLocation } from "react-router";
import SlickSlider from "../../../Layout/ImageSlider/SlickSlider";
//import { Carousels } from "../../../../src/Layout/Carousel";

function InterfaceOne() {
  const location = useLocation();
  const { userData } = useContext(UserContext);
  const islogin = location.pathname === "/login";
  const { banners, foods, restaurants, isLoading } = useRestaurants(backendURL);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  useEffect(() => {
    setFilteredRestaurants(restaurants);
  }, [isLoading]);

  return (
    <div className=" py-8 ">
      {" "}
      {islogin ? null : userData &&
        userData.servicemanSaBpartnerId &&
        userData.saOrgId ? (
        <ToggleCart />
      ) : null}
      <div className="bg-white relative ">
        {/* <Header /> */}
        {/* banners */}
        {/* <BannerList banners={banners} isLoading={isLoading} /> */}

        <SlickSlider
          slides={3}
          //   title="Auto Play Example"
          rtl={false}
          lazyLoad={true}
          autoplay={true}
        />
        {/* food list */}
        {/* <FoodList foods={foods} isLoading={isLoading} /> */}
        <RestaurantList
          isLoading={isLoading}
          restaurants={filteredRestaurants}
        />
        <Footer />
      </div>
    </div>
  );
}

export default InterfaceOne;

// const [bgImage, setbgImage] = useState([]);

// useEffect(() => {
//   Axios.get("https://picsum.photos/v2/list?page=1&limit=1").then((res) => {
//     setbgImage(res.data);
//   });
// }, []);
