import React, { useState, useEffect } from "react";
import Context from "./index";

const LocationProvider = (props) => {
  // const [userData, setUserData] = useState({});
  const [waiterLocationData, setWaiterLocationData] = useState([]);
  const [waiterin, setWaiterin] = useState([]);
  //   const addUserData = (user_data) => {
  //     console.log("Data Set");
  //     setUserData(user_data);
  //   };

  const addWaiterLocationData = (location_data) => {
    console.log("Waiter Location Data Set");
    setWaiterLocationData(location_data);
  };

  const WaiterInLocation = (location_in) => {
    // console.log("Waiter Location Data Set");
    setWaiterin(location_in);
  };

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("locationDetails");
    if (storedData) {
      setWaiterLocationData(JSON.parse(storedData));
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("locationDetails", JSON.stringify(waiterLocationData));
  }, [waiterLocationData]);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("fetchlocation", JSON.stringify(waiterin));
  }, [waiterin]);

  // console.log(waiterin);

  // useEffect to handle local storage
  useEffect(() => {
    // Get the current stored value from local storage
    const storedloc = localStorage.getItem("waiterinlocation");
    const storedlocid = localStorage.getItem("waiterinlocationid");
    // Check if the stored value is different from the current state
    if (storedloc !== waiterin.name) {
      // Update the state with the stored value
      setWaiterin((prev) => ({
        ...prev,
        name: storedloc,
      }));
    }
    if (storedlocid !== waiterin.id) {
      // Update the state with the stored value
      setWaiterin((prev) => ({
        ...prev,

        id: storedlocid,
      }));
    }
  }, []);
  // Empty dependency array means this effect runs once after the component mounts

  // useEffect to update local storage when waiterintable.table changes
  useEffect(() => {
    // Update local storage with the current value of waiterintable.table
    localStorage.setItem("waiterinlocation", waiterin.name);
  }, [waiterin.name]);

  useEffect(() => {
    // Update local storage with the current value of waiterintable.table
    localStorage.setItem("waiterinlocationid", waiterin.id);
  }, [waiterin.id]);

  // console.log(waiterin);
  const fetchedid = waiterin.id;
  return (
    <Context.Provider
      value={{
        ...props,
        // userData,
        // addUserData: addUserData,
        waiterLocationData,
        addWaiterLocationData: addWaiterLocationData,
        waiterin,
        WaiterInLocation: WaiterInLocation,
        fetchedid,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default LocationProvider;
