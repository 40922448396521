import { useContext, useState } from "react";
// import WaiterTable from "../../../../Layout/WaiterLayouts/WaiterTable";
import {
  GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
  GET_POS_MASTER_STATUS_FOR_PO,
  defaultPath,
  posMasterId,
} from "../../../../utils/constants";
import { useEffect } from "react";
import Footer from "../../../../Layout/Footer";
import useRestaurants from "../../../../Hooks/useRestaurants";
import { Breadcrumb, Button, Col, Container, Row } from "reactstrap";
import { Fragment } from "react";
import Widgets1 from "./Widgets1";
import Widgets2 from "./Widgets2";
import Widgets3 from "./Widgets3";
// import { useNavigate } from "@reach/router";
import ProductModal from "./ProductModal";
import { Btn } from "../../../../AbstractElements";
import { backendURL } from "../../../../Config/Config";
import { useParams } from "react-router";
import axios from "axios";
import ProductDetails from "./ProductDetails";
import OngoingDetails from "./ongoingDetails";
import BillRequest from "./BillRequest";
import TableContext from "../../../../_helper/TableHolder";
import UserContext from "../../../../_helper/User";
import LocationContext from "../../../../_helper/Location";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  selectItemsInCart,
} from "../../../../cart/cartSlice";
import { useRef } from "react";
import ToggleCart from "../ToggleCart";
import Reload from "../Reload";
import PopupComponent from "../../../../Layout/TopNavBar/Showpopup";
import PosMasterContext from "../../../../_helper/PosMaster";

// import { useUserContext } from "../../../../Hooks/usePosDetails";
// import { Button } from "react-bootstrap";

function Waiter() {
  const {
    //   orgId: routeOrgId,
    //   locationId: routeLocationId,
    //   tableId: routeTableId,
    categoryId,
  } = useParams();
  const dispatch = useDispatch();

  // const [isLoading, setIsLoading] = useState(true);
  // const { orgId, locationId, tableId } = defaultPath;
  const [tables, setTables] = useState([]);
  // const { setUserDataContext } = useUserContext();
  const quantity = 1;
  const [dataid, setDataid] = useState("");
  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [POSMasterStatus, setPOSMasterStatusMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updatedwidget, setupdateddata] = useState(true);
  const { addTableData, WaiterInTable } = useContext(TableContext);
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const { addposData } = useContext(PosMasterContext);
  // Add this state at the top of your Waiter component
  const [previousCart, setPreviousCart] = useState(null);

  const cartItems = useSelector(selectItemsInCart);
  localStorage.setItem(`waiterintable_pretable`, JSON.stringify(cartItems));
  console.log(cartItems);
  console.log(tables);

  // const onOpenModal = (productId, data) => {
  //   setOpenModal(true);
  //   // setModalComponent(true);
  //   setDataid(productId);
  //   setData(data);
  // };

  const onOpenModal = (productId, data) => {
    // Check if there is a previously identified cart
    if (previousCart) {
      // Show the confirmation popup

      return; // Do not proceed with opening the modal
    }

    // Open the modal for the current table
    setOpenModal(true);
    setDataid(productId);
    setData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (fetchingData) return; // If already fetching, skip this iteration

      // setFetchingData(true);
      const requestData = {
        orgId: userData.userData.saOrgId,
        locationId: waiterin.waiterin.id,
      };

      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        const tableResponse = await reqInstance.post(
          GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS,
          requestData
        );

        if (tableResponse) {
          const tableResult = tableResponse.data;
          addTableData(tableResult);

          if (!tableResult.hasError) {
            setTables(tableResult.tableDetail);
            console.log(tableResult);
            const POSMasterStatus = await reqInstance.post(
              GET_POS_MASTER_STATUS_FOR_PO,
              requestData
            );
            if (POSMasterStatus) {
              const POSMasterStatusResult1 = POSMasterStatus.data;

              if (!POSMasterStatusResult1.hasError) {
                console.log(POSMasterStatusResult1);
                setPOSMasterStatusMenu(POSMasterStatusResult1);

               
                setIsLoading(false);
              } else {
                console.error(
                  "Error in subcategory API response:",
                  POSMasterStatusResult1.errorMessage
                );
                setIsLoading(false);
              }
            }
            const POSMasterStatusTable = tableResult.tableDetail.map(
              (posMasterItem) => {
                const {
                  posMasterSearchKey,
                  saPosMasterId,
                  subTotal,
                  status,
                  openBillStatus,
                  saPosTableId,
                } = posMasterItem;

                let POSMasterData;
                POSMasterData = {
                  posMasterSearchKey: posMasterSearchKey,
                  saPosMasterId: saPosMasterId,
                  subTotal: subTotal,
                  status: status,
                  openBillStatus: openBillStatus,
                  saPosTableId: saPosTableId,
                };
                // setUserDataContext(POSMasterData);
                console.log(POSMasterData);
                
                return POSMasterData;
              }
            );

            setTables(POSMasterStatusTable);
            const transformedTables = tableResult.tableDetail
              .map((tableItem) => {
                const {
                  saPosTableStatus,
                  saPosTableId,
                  saPosTableName,
                  currency,
                  subTotal,
                  pmsearchkey,
                  saPosMasterId,
                  servicemanSaBpartnerName,
                } = tableItem;

                let statusData;

                switch (saPosTableStatus) {
                  case 0:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "Available",
                      gros: 50,
                      color: "success",
                      icon: "cart",
                      searchKey: pmsearchkey,
                      posMasterIdTable: saPosMasterId,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  case 1:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "On-Going",
                      gros: 50,
                      color: "secondary",
                      icon: "return-box",
                      searchKey: pmsearchkey,
                      posMasterIdTable: saPosMasterId,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  case 2:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "Request-Bill",
                      gros: 50,
                      color: "primary",
                      icon: "rate",
                      searchKey: pmsearchkey,
                      posMasterIdTable: saPosMasterId,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  // default:
                  //   statusData = null;
                }

                return statusData;
              })
              .filter(Boolean);

            setTables(transformedTables);
          } else {
            console.error(
              "Error in category API response:",
              tableResult.errorMessage
            );
          }
        }

        if (tableResponse) {
          const tableResult = tableResponse.data;

          if (!tableResult.hasError) {
            setTables(tableResult.tableDetail);

            const transformedTables = tableResult.tableDetail
              .map((tableItem) => {
                const {
                  saPosTableStatus,
                  saPosTableId,
                  saPosTableName,
                  currency,
                  subTotal,
                  saPosMasterId,
                  pmsearchkey,
                  servicemanSaBpartnerName,
                } = tableItem;

                let statusData;

                switch (saPosTableStatus) {
                  case 0:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "Available",
                      gros: 50,
                      color: "success",
                      icon: "cart",
                      saPosMasterId: saPosMasterId,
                      possearchkeytable: pmsearchkey,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  case 1:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "On-Going",
                      gros: 50,
                      color: "secondary",
                      icon: "return-box",
                      saPosMasterId: saPosMasterId,
                      possearchkeytable: pmsearchkey,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  case 2:
                    statusData = {
                      status: saPosTableStatus,
                      id: saPosTableId,
                      table: saPosTableName,
                      title: "Request-Bill",
                      gros: 50,
                      color: "primary",
                      icon: "rate",
                      saPosMasterId: saPosMasterId,
                      possearchkeytable: pmsearchkey,
                      subtotal: `${currency} ${subTotal.toFixed(2)}`,
                      servicemanSaBpartnerName: servicemanSaBpartnerName,
                    };
                    break;
                  // default:
                  //   statusData = null;
                }

                return statusData;
              })
              .filter(Boolean);

            setTables(transformedTables);
          } else {
            console.error(
              "Error in category API response:",
              tableResult.errorMessage
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    // Fetch data on component mount

    // Interval for data fetch (every 5 minutes in this example)

    const fetchInterval = setInterval(() => {
      fetchData();
    }, 30000); //  30s in

    // Clear the interval on component unmount
    return () => clearInterval(fetchInterval);

    // Cleanup function to clear the interval when the component is unmounted
  }, [userData.userData.saOrgId, waiterin.waiterin.id, categoryId]);

  return (
    <Fragment>
      <Container fluid={true} className="general-widget">
        <div className="bg-white relative py-8">
          <Reload />
          <Row>
            {tables.map((table) => {
              switch (table.status) {
                case 0:
                  return (
                    <Col
                      lg="3"
                      md="3"
                      sm="4"
                      key={table.id}
                      className="box-col-4 pointer"
                      data-toggle="modal"
                      onClick={() => {
                        // // Set the previousCart state when a table is clicked
                        // setPreviousCart({
                        //   table: table.table,
                        //   items: table.items, // Include any other relevant cart information
                        // });

                        // Open the modal for the current table

                        WaiterInTable(table);
                        onOpenModal(1, table);
                      }}
                      cursor="pointer"
                    >
                      {
                        <Widgets1
                          data={{
                            item: table,
                            id: table.id,
                            table: table.table,
                            title: "Available",
                            gros: 50,
                            color: "success",
                            icon: "cart",
                            subtotal: table.subtotal,
                          }}
                        />
                      }
                    </Col>
                  );
                case 1:
                  return (
                    <Col
                      lg="3"
                      md="3"
                      sm="4"
                      key={table.id}
                      className="box-col-4 pointer"
                      data-toggle="modal"
                      onClick={() => {
                        // Set the previousCart state when a table is clicked
                        // setPreviousCart({
                        //   table: table.table,
                        //   items: table.items, // Include any other relevant cart information
                        // });

                        // Open the modal for the current table

                        WaiterInTable(table);
                        onOpenModal(2, table);
                      }}
                      cursor="pointer"
                    >
                      {table.status && (
                        <Widgets2
                          data={{
                            item: table,
                            id: table.id,
                            table: table.table,
                            title: "Ongoing",
                            gros: 50,
                            color: "secondary",
                            icon: "return-box",
                            subtotal: table.subtotal,
                            searchKeytable: table.possearchkeytable,
                            posMasterId: table.saPosMasterId,
                            sSaBpartnerName: table.servicemanSaBpartnerName,
                          }}
                        />
                      )}
                    </Col>
                  );
                case 2:
                  return (
                    <Col
                      lg="3"
                      md="3"
                      sm="4"
                      key={table.id}
                      className="box-col-4 pointer"
                      data-toggle="modal"
                      onClick={() => {
                        // Set the previousCart state when a table is clicked
                        // setPreviousCart({
                        //   table: table.table,
                        //   items: table.items, // Include any other relevant cart information
                        // });

                        // Open the modal for the current table

                        WaiterInTable(table);
                        onOpenModal(3, table);
                      }}
                      cursor="pointer"
                    >
                      {table.status && (
                        <Widgets3
                          data={{
                            item: table,
                            id: table.id,
                            table: table.table,
                            title: "Request-Bill",
                            gros: 50,
                            color: "primary",
                            icon: "rate",
                            subtotal: table.subtotal,
                            sSaBpartnerName: table.servicemanSaBpartnerName,
                          }}
                        />
                      )}
                    </Col>
                  );
                // default:

                //   return null;
              }
            })}

            {openModal && (
              <ProductModal
                value={openModal}
                setOpenModal={setOpenModal}
                dataid={dataid}
                data={data}
              />
            )}
          </Row>

          <Footer />
        </div>
      </Container>
    </Fragment>
  );
}

export default Waiter;
