import React, { Fragment, useContext, useEffect, useState } from "react";
import { Btn, H3, LI, P, UL } from "../../../../AbstractElements";
// import { Brand, Availability, AddToCart, BuyNow } from '../../../../';
import ProductContext from "../../../../_helper/Ecommerce/Product";
import WishtListContext from "../../../../_helper/Ecommerce/Wishlist";
// import SocialIcons from './SocialIcons';
// import StarRatings from './StarRating';
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CartContext from "../../../../_helper/Ecommerce/Cart";
import CustomizerContext from "../../../../_helper/Customizer";
import { Brand, Availability, AddToCart, BuyNow } from "../../../../Constant";
import { defaultPath } from "../../../../utils/constants";
import { selectItemsInCart } from "../../../../cart/cartSlice";
import { useSelector } from "react-redux";
import { ExternalLink, ShoppingBag } from "react-feather";
import UserContext from "../../../../_helper/User";
import LocationContext from "../../../../_helper/Location";
import TableContext from "../../../../_helper/TableHolder";
import PosMasterContext from "../../../../_helper/PosMaster";
import { backendURL } from "../../../../Config/Config";
import axios from "axios";

const OngoingDetails = ({ data }) => {
  const cartItems = useSelector(selectItemsInCart);
  const { layoutURL } = useContext(CustomizerContext);
  const itemsInCart = useSelector(selectItemsInCart);
  const { productItem, symbol } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);
  const [productss, setProductss] = useState("");
  const path = window.location.pathname.split("/").pop();
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const { addposData, posData } = useContext(PosMasterContext);
  const [showPopupconfirm, setShowPopupconfirm] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const cartItem = cartItems[0];
  const tableName = cartItem && cartItem.item && cartItem.item.tableName;
  const tableId = cartItem && cartItem.item && cartItem.item.tableId;
  const tableIdFromCartItem = data.id;

  console.log(tableIdFromCartItem);

  console.log(tableId);

  const tab = JSON.parse(localStorage.getItem(`cart${cartItems.tableId}`));
  console.log(tab);
  const openpopup = () => {
    setShowPopupconfirm(true);
  };

  const handleyes = () => {
    handleRequestBillClick();
    setShowPopupconfirm(false);
    // window.location.reload();
  };
  const handleno = () => {
    // dispatch(clearCart(true));
    setShowPopupconfirm(false);
  };

  const handleRequestBillClick = async () => {
    setShowPopup(true);
    try {
      // Set showLoader to true when starting the bill request

      const reqInstance = axios.create({
        baseURL: backendURL,
      });

      // Send a request to the bill request endpoint
      const billRequestData = {
        posMasterId: posData.saPosMasterId,
        // Add any additional data needed for the request
      };

      const billRequestResponse = await reqInstance.post(
        "/updateOpenBillPrintRequestStatusForPO",
        billRequestData
      );

      const billRequestResult = billRequestResponse.data;
      console.log("Bill Request Result:", billRequestResult);

      // Check if the bill request was successful
      if (!billRequestResult.hasError) {
        // Display an alert for a successful bill request
        setShowPopupSuccess(true);
        // Log a message to the console
        console.log("Bill request sent successfully!");
      } else {
        // Handle the case where the bill request was not successful
        console.error(
          "Error sending bill request:",
          billRequestResult.errorMsg
        );
        setShowPopupError(true);
      }
    } catch (error) {
      console.error("Error sending bill request:", error.message);
      setShowPopupError(true);

      // Handle error as needed
    }
  };

  useEffect(() => {
    productItem.map((item) => {
      if (item.id === path) {
        setProductss(item);
      }
      return null;
    });
  });

  const quantity = 1;
  const history = useNavigate();
  const { addToWishList } = useContext(WishtListContext);

  const singleItem = productItem;
  const addWishList = (product) => {
    addToWishList(product);
    history(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist/${layoutURL}`);
  };

  const AddToCarts = (item, quantity) => {
    addToCart(item, quantity);
  };

  const buyProduct = (item, quantity) => {
    addToCart(item, quantity);
  };

  const navigateToInterfaceOne = () => {
    // Use the <a> tag with the href attribute to navigate
    window.location.href = {};
  };

  console.log(data);
  addposData(data);
  console.log(addposData(data));

  return (
    <Fragment>
      <Col xxl="12" className="box-col-8 order-xxl-0 order-1">
        <div className="product-page-details my-2">
          <H3 className="txt-danger fs-2">{"Now ongoing"}</H3>
        </div>
        <CardBody className="">
          <hr />
          <div className="p-t-10 p-b-10">
            <table className="product-page-width fs-5 text-muted">
              <tbody className="">
                <tr>
                  <td>
                    <b>{"Table :"} &nbsp;&nbsp;&nbsp;</b>
                  </td>
                  <td className="txt-danger">{data.table}</td>
                </tr>
                {/* <tr>
                  <td>
                    <b>{"Status"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b>
                  </td>
                  <td className="txt-success fs-6">
                    {"items:"}&nbsp;{itemsInCart.length}
                  </td>
                </tr> */}
                <tr>
                  <td>
                    <b>{"Waiter :"} &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</b>
                  </td>
                  <td>{data.servicemanSaBpartnerName}</td>
                </tr>
                <tr>
                  <td>
                    <b>{"Sub Total :"} &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</b>
                  </td>
                  <td>{data.subtotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="d-flex justify-content-center"
          >
            <ul
              className="p-2 d-flex inline"
              style={{
                listStyleType: "none",
                margin: 0,
                padding: 0,
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <li>
                {" "}
                <Link
                  to={`/sample-page/interface-one/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${data.id}`}
                >
                  <Btn
                    attrBtn={{
                      className: "btn btn-pill btn-air-success btn-square ",
                      color: "success",
                    }}
                    style={{ minWidth: "max-content" }}
                  >
                    {"View Menu"}
                  </Btn>
                </Link>
              </li>
              <li>
                <Link
                  to={`/order/${waiterintable.waiterintable.saPosMasterId}`}
                >
                  <Btn
                    attrBtn={{
                      className:
                        "btn btn-pill btn-air-primary btn-square  inline",
                      color: "secondary",
                    }}
                    style={{ minWidth: "max-content" }}
                  >
                    {"View Order"}
                  </Btn>
                </Link>
              </li>
              {tableIdFromCartItem == tableId &&
                cartItems &&
                cartItems.length !== 0 && (
                  <li>
                    <Link
                      to={`/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${tableId}/cart`}
                    >
                      <Btn
                        attrBtn={{
                          className:
                            "d-flex btn btn-pill btn-air-warning btn-square  inline ",
                          color: "warning",
                        }}
                        style={{ minWidth: "max-content" }}
                      >
                        {"View Cart"}
                      </Btn>
                    </Link>
                  </li>
                )}
            </ul>
          </span>{" "}
          <ul className="pt-3">
            {" "}
            <li
              className="flex w-full justify-content-center
            "
            >
              <Btn
                attrBtn={{
                  onClick: () => openpopup(),
                  className:
                    "d-flex w-full justify-content-center btn btn-pill btn-air-warning btn-square inline ",
                  color: "warning",
                }}
                style={{ minWidth: "max-content" }}
              >
                {/* // onClick={() => openpopup()}
                // color="success" // className="m-r-10 m-t-10" className="
                bg-orange-500 p-2 rounded-md flex items-center fs-6 text-white"
                // onClick={navigateToInterfaceOne}
                onClick={() => openpopup()} */}
                {/* style={{ minWidth: "max-content" }} */}
                {/* <ShoppingBag className="w-4 h-4 text-gray-700" /> */}
                {/* <i
                  className="d-flex justify-content-center 
                 fa fa-print me-1 "
                ></i> */}
                {"Confirm Bill"}
              </Btn>
            </li>
          </ul>
          {/* Popup for Success */}
          {showPopupSuccess && (
            <div className="popup-overlay">
              <div className="popup-container">
                <h1 className="fs-2">Bill Request Successful!</h1>
                <p className="fs-6 pb-3"> </p>
                <button
                  className="btnpop"
                  onClick={() => setShowPopupSuccess(false)}
                >
                  OK
                </button>
              </div>
            </div>
          )}
          {/* Popup for Error */}
          {showPopupError && (
            <div className="popup-overlay">
              <div className="popup-container">
                <h1 className="fs-2">Bill Request Failed!</h1>
                <p className="fs-6 pb-3">
                  An error occurred while sending the bill request.
                </p>
                <button
                  className="btnpop"
                  onClick={() => setShowPopupError(false)}
                >
                  OK
                </button>
              </div>
            </div>
          )}
          {/* Popup */}{" "}
          {showPopupconfirm && (
            <div className="popup-overlay">
              <div className="popup-container">
                <h1 className="fs-4 text-warning">
                  Are You Sure, You Want To Confirm the Bill Request?
                </h1>
                {/* <p className="fs-6 pb-3">
                 
                </p> */}

                <ul
                  className="p-2 d-flex inline"
                  style={{
                    listStyleType: "none",
                    // margin: 0,
                    // padding: 0,
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <li>
                    <button
                      className=" btnpop  "
                      onClick={handleno}
                      style={{ minWidth: "max-content" }}
                    >
                      Add More
                    </button>
                  </li>{" "}
                  or
                  <li>
                    <button
                      className=" btnpop "
                      onClick={handleyes}
                      style={{ minWidth: "max-content" }}
                    >
                      Confirm
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </CardBody>

        {/* </Card> */}
        {/* </Row> */}
      </Col>
    </Fragment>
  );
};
export default OngoingDetails;

// <div className="d-flex justify-content-center p-t-20">
//   <Link to={`/order/${waiterintable.waiterintable.saPosMasterId}`}>
//     <button
//       // color="warning"
//       // className="m-r-10 m-t-10"
//       className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2 text-sm text-secondary"
//       // onClick={navigateToInterfaceOne}
//     >
//       <i className="d-flex justify-content-center fa fa-external-link me-1 "></i>
//       {"Settle Bill"}
//     </button>
//   </Link>
//   |
//   <Link
//     to={`/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${data.id}/cart`}
//   >
//     <button
//       // color="warning"
//       // className="m-r-10 m-t-10"
//       className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2 text-sm text-secondary"
//       // onClick={navigateToInterfaceOne}
//     >
//       <i className="d-flex justify-content-center fa fa-shopping-cart me-1 "></i>
//       {"View Cart"}
//     </button>
//   </Link>
//   |
//   {/* </div>
//             <div className="d-flex justify-content-center p-t-20"> */}
//   <Link
//     to={`/sample-page/interface-one/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${data.id}`}
//   >
//     <button
//       // color="secondary"
//       // className="m-r-10 m-t-10"
//       className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2 text-sm text-secondary"
//       // onClick={navigateToInterfaceOne}
//     >
//       <i className="d-flex justify-content-center fa fa-shopping-basket me-1 "></i>
//       {"Order More"}
//     </button>
//   </Link>
// </div>;

//  <Link
//    to="/order"
//    // className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
//  >
//    <button
//      // color="success"
//      // className="m-r-10 m-t-10"
//      className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2 text-sm text-secondary"
//      // onClick={navigateToInterfaceOne}
//    >
//      {/* <ShoppingBag className="w-4 h-4 text-gray-700" /> */}
//      <i className="d-flex justify-content-center fa fa-shopping-bag me-1 "></i>
//      {"view order"}
//    </button>
//  </Link>;
