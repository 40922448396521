import React from "react";
import { Card, CardBody } from "reactstrap";
// import { H4 } from "../../AbstractElements";
// import SvgIcon from "../Component/SvgIcon";
// import CountUp from "react-countup";
// import { H4 } from "../../../../AbstractElements";
import SvgIcon from "../../../Common/Component/SvgIcon";

const WaiterLocationCard = ({ data, mainClass }) => {

  return (
    <Card className={` small-widget ${mainClass ? mainClass : ""}bg-success`}>
      <CardBody className={data.color}>
        {/* <div> */}
        <span className="fs-6 fw-bold p-r-5 ">{data.name}</span>
        <div className="text-white-50 sm p-r-5 ">
          {"Type:"}&nbsp;{data.description}
        </div>
        {/* </div> */}
        <div className="bg-gradient ">
          <SvgIcon iconId={data.icon} className="stroke-icon " />
        </div>
      </CardBody>
    </Card>
  );
};

export default WaiterLocationCard;
