// import { useContext } from "react";
import UserContext from "../_helper/User";
import LocationContext from "../_helper/Location";
import TableContext from "../_helper/TableHolder";

// export const CDN_URL = `https://qrorder.saprosolutions.com/SaproPO-1.0.1/GetAllActiveCategoryForPO/`;
export const CDN_URL = `https://ichathistory.saprosolutions.com/SaproPO/`;
//export const BASE1_API_URL = 'https://picsum.photos/800/600'

export const GET_RESTAURANTS_URL = "";
//   "https://qrorder.saprosolutions.com/SaproPO-1.0.1/";
export const GET_ALL_ACTIVE_CATEGORY_FOR_PO = "getAllActiveCategoryForPO";
export const GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO =
  "getAllActiveItemByCategoryForPO";
export const CHECK_LOGIN_DETAIL_FOR_PO = "checkLoginDetailForPO";
export const GET_ALL_ACTIVE_POS_LOCATION_FOR_PO =
  "getAllActivePOSLocationForPO";
export const GET_LOCATION_WISE_ALL_TABLE_WITH_STATUS =
  "getLocationWiseAllTableWithStatus";
export const GET_POS_MASTER_STATUS_FOR_PO = "getPOSMasterStatusForPO";

export let defaultPath = getDefaultPath();

function getDefaultPath(userData, waiterin, waiterintable) {
  // const userData = useContext(UserContext);
  // const waiterin = useContext(LocationContext);
  // const waiterintable = useContext(TableContext);
  let defaultPath = {
    orgId: 41,
    locationId: 544,
    tableId: 694,
  };

  if (userData && userData.userData) {
    defaultPath.orgId = userData.userData.saOrgId || defaultPath.orgId;
  }

  if (waiterin && waiterin.waiterin) {
    defaultPath.locationId = waiterin.waiterin.id || defaultPath.locationId;
  }

  if (waiterintable && waiterintable.waiterintable) {
    defaultPath.tableId = waiterintable.waiterintable.id || defaultPath.tableId;
  }

  return defaultPath;
}

export const logindefault = {
  password: 123,
  orgcode: "DEMO",
};

export const Locdefault = {
  ordId: 41,
};

export const tablestatus = {
  orgId: 41,
  locationId: 544,
};
export const posMasterIddefault = {
  posMasterId: 130,
};

// export const statics = ({ location }) => ({
//   saLocationId: location.saLocationId,
//   searchKey: location.searchKey,
//   name: location.name,
//   description: location.description,
// });

// export const selectDetails = ({ details }) => details?.items;

// export const commoneStates = (state) => {
//   const saLocationId = selectDetails(state)?.[0]?.item?.saLocationId;

//   return { saLocationId };
// };
