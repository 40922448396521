import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { H4 } from "../../../AbstractElements";

// import SvgIcon from "../Component/SvgIcon";
// import SquareGroupUi from "../../Dashboard/OnlineCourse/SquareGroupUi";
import SvgIcon from "../../Common/Component/SvgIcon";
import UserContext from "../../../_helper/User";
import LocationContext from "../../../_helper/Location";
import TableContext from "../../../_helper/TableHolder";
import { useState } from "react";
import {
  addToCart,
  selectItemsInCart,
  selectTotalPriceWithCurrency,
} from "../../../cart/cartSlice";
import { useSelector } from "react-redux";

const CourseBox = ({ totalItems, mainClass }) => {
  const [itemCounts, setItemCounts] = useState({});
  // const { addToCart } = useContext(CartContext);
  const [productss, setProductss] = useState("");
  const quantity = 1;
  const isFooterVisible = totalItems > 0;
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);

  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  // const itemsInCart = useSelector((state) => selectItemsInCart(state, tableId));

  const AddToCarts = (item, quantity) => {
    addToCart(item, quantity);
  };
  const itemsInCart = useSelector(selectItemsInCart);
  const { totalPrice, currency, orderids } = useSelector(
    selectTotalPriceWithCurrency
  );

  const WidgetsData1WidgetsPage = {
    title: `Total Items: ${itemsInCart.length}`,
    gros: 50,
    total: `Total Price: ${currency} ${totalPrice.toFixed(2)}`,
    color: "warning",
    icon: "cart",
    link: "view Cart",
  };

  return (
    <Card className={`course-box ${mainClass ? mainClass : ""} bg-gray-200`}>
      <CardBody>
        <Link
          to={`/interface-one/restaurants/${orgId}/${locationId}/${tableId}/cart`}
        >
          <div className="course-widget">
            <div
              style={{ width: "36px", height: "36px" }}
              className={`course-icon ${
                WidgetsData1WidgetsPage.color
                  ? WidgetsData1WidgetsPage.color
                  : ""
              }`}
            >
              <SvgIcon
                style={{ width: "16px", height: "16px" }}
                className="fill-icon "
                iconId={WidgetsData1WidgetsPage.icon}
              />
            </div>
            <div className="">
              <H4 attrH4={{ className: "mb-0 bold" }}>
                {WidgetsData1WidgetsPage.total}
              </H4>
              {/* <span className="f-light text- fw-6 bold">
                {WidgetsData1WidgetsPage.title}
              </span> */}
            </div>
          </div>
          <span className=" text-orange-500 d-flex justify-content-center f-bold pt-2">
            {"View Cart"}{" "}
            <span className="ms-2 f-light ">
              <SvgIcon
                style={{ width: "16px", height: "16px" }}
                className="fill-icon f-light"
                iconId="arrowright"
              />
            </span>
          </span>
        </Link>
      </CardBody>
      {/* <SquareGroupUi /> */}
    </Card>
  );
};

export default CourseBox;
