import React from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { P } from "../../AbstractElements";

const Footer = () => {
  return (
    <footer>
      {/* <Container fluid={true}> */}

      <div className="container-max text-center py-3 border-t">
        Quality Product Made by{" "}
        <a
          href="https://www.saprosolutions.com/en/"
          target={"_blank"}
          className="text-orange-500"
        >
          Sapro
        </a>
      </div>
      {/* </Container> */}
    </footer>
  );
};

export default Footer;
// <Fragment>
// <footer className="footer">
{
  /*  */
}
{
  /* <Row>
            <Col md="12" className="footer-copyright text-center">
              <P attrPara={{ className: "mb-0" }}>{'Copyright 2022 © Cuba theme by pixelstrap.'}</P>
            </Col>
          </Row> */
}
{
  /*  */
}
// </footer>
// </Fragment>
