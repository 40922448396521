import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cartReducer from "../../src/cart/cartSlice";
import appReducer from "../../src/app/appSlice";
import addressReducer from "../../src/address/addressSlice";
import waiterLocationReducer from "../../src/cart/WaiterSlice";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";

let rootReducer = combineReducers({
  cart: cartReducer,
  app: appReducer,
  address: addressReducer,
  waiterLocation: waiterLocationReducer,
});

const persistConfig = {
  key: "root",
  storage,
  // Add any configuration options you need
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
