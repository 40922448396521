import React from "react";
import { Card, CardBody } from "reactstrap";
// import { H4 } from '../../AbstractElements';
import SvgIcon from "../../../Common/Component/SvgIcon";
import { H1, H4 } from "../../../../AbstractElements";
import { CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import { defaultPath } from "../../../../utils/constants";
import UserContext from "../../../../_helper/User";
import LocationContext from "../../../../_helper/Location";
import { useContext } from "react";
import TableContext from "../../../../_helper/TableHolder";
import PopupComponent from "../../../../Layout/TopNavBar/Showpopup";
// import SvgIcon from '../Component/SvgIcon';

const Widgets1 = ({ data }) => {
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  // const waiterintable = useContext(TableContext);
  // const storedTableid = localStorage.getItem("waiterintable_tableid");
  // console.log(userData);
  return (
    <Link
      to={`/sample-page/interface-one/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${data.id}`}
    >
      <Card className="widget-1 bg-success  ">
        {/* <CardBody className=""> */}
        <div className="widget-content card pt-2 mb-1">
          <div className={`widget-round ${data.color} `}>
            <div className="bg-round ">
              <SvgIcon className=" " iconId={`${data.icon}`} />
              <SvgIcon className="half-circle svg-fill " iconId="halfcircle" />
            </div>{" "}
          </div>{" "}
          <div>
            <h5 className="fs-6 text-success ">
              <span className="table-heading  p-l-10 p-r-10">Table: </span>{" "}
              {data.table}
            </h5>

            {/* <H4>{data.total}</H4> */}
          </div>
        </div>
        <div className="p-l-5">
          {" "}
          <h3 className="fs-5 text-black bold d-flex align-items-center">
            {" "}
            <span className="table-heading ext-balck">Sub Total : </span>{" "}
            {data.subtotal}
          </h3>
        </div>
        <div className="fs-6 text-black d-flex  align-item-center justify-content-center p-l-10 p-r-10 bg-success text-white">
          {data.title}
        </div>
        {/* <div className={`font-${data.color} f-w-500`}>
          <i
            className={`icon-arrow-${
              data.gros < 50 ? "down" : "up"
            } icon-rotate me-1`}
          />
          <span>{`${data.gros < 50 ? "-" : "+"}${data.gros}%`}</span>
        </div> */}
        {/* </CardBody> */}
      </Card>
    </Link>
  );
};

export default Widgets1;
