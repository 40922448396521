import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import { Breadcrumb, Card, CardBody } from "reactstrap";
// import CartItemList from "../../../Layout/Cartdata/CartItemList";
import { useDispatch, useSelector } from "react-redux";
import {
  selectItemsInCart,
  selectTotalPriceWithCurrency,
  // selectTotalPriceWithCurrency,
  setOrderPlaced,
} from "../../../cart/cartSlice";
import {
  GET_POS_MASTER_STATUS_FOR_PO,
  defaultPath,
  posMasterIddefault,
} from "../../../utils/constants";
import { H6, P, Spinner } from "../../../AbstractElements";
import { backendURL } from "../../../Config/Config";
import axios from "axios";
import PosMasterContext from "../../../_helper/PosMaster";
import OrderedItemList from "../../../Layout/Cartdata/OrderedItemList";
import { selectCartHistory } from "../../../cart/OrderedSlice";
import {
  DateAndTime,
  Price,
  ProductName,
  Quantity,
  Sub_total,
  Timeline,
} from "../../../Constant";
import { DateEnv } from "@fullcalendar/react";
import UserContext from "../../../_helper/User";
import LocationContext from "../../../_helper/Location";
import TableHolderProvider from "../../../_helper/TableHolder/TableHolderProvider";
import TableContext from "../../../_helper/TableHolder";
import ToggleCart from "./ToggleCart";
import InvoiceContain from "../../../Layout/Invoice";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import defaultImage from "../../../assets/images/default/def.png";
// import { useUserContext } from "../../../Hooks/usePosDetails";
// import RestaurantSystem, { PosManagementComponent } from "./RestaurantSystem";
// import { useData } from "../../../Hooks/usePosDetails";

const LoaderSpinner = ({ heading, spinnerClass }) => (
  <Col md="3">
    <H6 attrH6={{ className: "sub-title mb-0 text-center" }}>{heading}</H6>
    <div className="loader-box">
      <Spinner className={spinnerClass} />
    </div>
  </Col>
);

const OrderDetails2 = ({ saPosMasterId = 130 }) => {
  // const { saPosMasterId } = useParams();

  const dispatch = useDispatch();
  // const { currency } = useSelector(selectTotalPriceWithCurrency);
  const navigate = useNavigate();
  const { addposData, posData } = useContext(PosMasterContext);
  const [currentDate, setCurrentDate] = useState(null);
  const [searchkey, setsearchkey] = useState(null);
  const [subtot, setSubtotal] = useState(null);
  const [filteredposid, setFilteredposId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupconfirm, setShowPopupconfirm] = useState(false);
  // const [showLoader, setShowLoader] = useState(false);
  const [cartitemset, setItemsInCart] = useState([]);
  const [cartitemsettotal, setTotalPrice] = useState([]);
  const [positems, setPOSMaster] = useState({});
  const [locationTables, setLocationTables] = useState([]);
  const [posMasterStatus, setPosMasterStatus] = useState([]);
  const [billRequestSent, setBillRequestSent] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const tableData = useContext(TableContext);
  const [orderData, setOrderData] = useState([]);
  let orgId = userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();
  const currency = positems.itemsList?.[0]?.currency;
  //  const [loading, setLoading] = useState(true); // State to control loading spinner visibility
  //   const [data, setData] = useState({
  //     id: 21,
  //     heading: "Loader 23",
  //     spinnerClass: "loader-24",
  //   });

  console.log(userData);
  console.log(posData.saPosMasterId);
  console.log(positems.posMasterSearchKey);
  console.log(currency);

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  console.log(waiterintable);
  console.log(tableData);
  console.log(tableId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if tableData is already populated

        const requestData = {
          posMasterId: posData.saPosMasterId,
        };
        console.log(requestData);

        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        const POSMasterResponse = await reqInstance.post(
          "/getPOSMasterStatusForPO",
          requestData
        );

        if (POSMasterResponse) {
          const POSMasterResult = POSMasterResponse.data;

          // Only update state if there is new data
          if (!POSMasterResult.hasError) {
            setPOSMaster(POSMasterResult);
            console.log(POSMasterResult);
          } else {
            console.error(
              "Error in category API response:",
              POSMasterResult.errorMessage
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [orgId, locationId, posData.saPosMasterId]); // Include orgId and locationId as dependencies

  console.log(orderData);
  console.log(posMasterStatus);

  const handleRequestBillClick = async () => {
    setShowPopup(true);
    try {
      // Set showLoader to true when starting the bill request

      const reqInstance = axios.create({
        baseURL: backendURL,
      });

      // Send a request to the bill request endpoint
      const billRequestData = {
        posMasterId: posData.saPosMasterId,
        // Add any additional data needed for the request
      };

      const billRequestResponse = await reqInstance.post(
        "/updateOpenBillPrintRequestStatusForPO",
        billRequestData
      );

      const billRequestResult = billRequestResponse.data;
      console.log("Bill Request Result:", billRequestResult);

      // Check if the bill request was successful
      if (!billRequestResult.hasError) {
        // Display an alert for a successful bill request
        setShowPopupSuccess(true);
        // Log a message to the console
        console.log("Bill request sent successfully!");
      } else {
        // Handle the case where the bill request was not successful
        console.error(
          "Error sending bill request:",
          billRequestResult.errorMsg
        );
        setShowPopupError(true);
      }
    } catch (error) {
      console.error("Error sending bill request:", error.message);
      setShowPopupError(true);

      // Handle error as needed
    }
  };

  const ordermore = () => {
    navigate(`/sample-page/interface-one/${orgId}/${locationId}/${tableId}`);
  };

  const handleClosePopup = () => {
    // dispatch(clearCart(true));

    // dispatch(storeCartHistory(true));
    setShowPopup(false);
    // setOrderPlaced(true);
    // dispatch(setShowItemCount(true));
  };

  const openpopup = () => {
    setShowPopupconfirm(true);
  };

  const handleyes = () => {
    handleRequestBillClick();
    setShowPopupconfirm(false);
    // window.location.reload();
  };
  const handleno = () => {
    // dispatch(clearCart(true));
    setShowPopupconfirm(false);
  };

  useEffect(() => {
    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const proceedorderno = JSON.parse(
      localStorage.getItem("waiterintable_possearchkeytable")
    );
    const subtotal = JSON.parse(localStorage.getItem("waiterintable_subtotal"));

    dispatch(setOrderPlaced(storedCartItems.length > 0)); // Update orderPlaced state based on cart items
    const currentDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(currentDate);
    setsearchkey(proceedorderno);
    setSubtotal(subtotal);
  }, [dispatch]);

  console.log(orderData);
  if (
    !positems ||
    positems == null ||
    positems.saPosMasterId == 0 ||
    tableId != positems.saPosTableId
  ) {
    return (
      <div className="flex grow min-h-[60vh] justify-center items-center">
        <p>No Orders Found!</p>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#eee" }}>
      <Fragment>
        <div className="container-max py-8 pb-16">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-xl-6">
              <div className="card border-top border-bottom border-3 border-color-custom">
                <div className="card-body p-3">
                  <ToggleCart />
                  <p className="lead fw-bold mb-5" style={{ color: "#f37a27" }}>
                    Order Status
                  </p>
                  <div className="row">
                    <div className="col mb-3">
                      <p className="small text-muted mb-1">Date</p>
                      <p>{currentDate}</p>
                    </div>
                    <div className="col mb-3">
                      <p className="small text-muted mb-1">Order No.</p>
                      <p> {positems.posMasterSearchKey}</p>
                    </div>
                  </div>
                  {/* add the span tag below commente to show the cart items proceeded */}
                  {/* <Container> */}
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          {" "}
                          <ul className="basis-8/12">
                            {positems.itemsList &&
                              positems.itemsList.map((item) => (
                                <li
                                  key={item.saItemMasterId}
                                  className="flex gap-4 justify-between max-w-[600px] my-4"
                                >
                                  <div className="bg-gray-50 d-flex p-2 basis-5/12 ">
                                    <div className="basis-8/12">
                                      <img
                                        className="w-full h-full md:h-auto object-cover block rounded-md aspect-square"
                                        src={
                                          item && item.imagePath
                                            ? `${backendURL}${item.imagePath}`
                                            : defaultImage
                                        }
                                        alt=""
                                      />
                                    </div>{" "}
                                  </div>
                                  <div className="basis-9/12">
                                    <p className="text-lg font-semibold">
                                      {item.name}
                                    </p>
                                    <p className="my-2 space-x-1">
                                      <span className="font-semibold">
                                        {item.currency}&nbsp;
                                        {parseFloat(
                                          item.qty *
                                            parseFloat(
                                              item.sellingPice.toFixed(2)
                                            )
                                        )}
                                      </span>
                                      <span className=" font-normal">
                                        ({item.sellingPice.toFixed(2)} ×{" "}
                                        {item.qty})
                                      </span>
                                    </p>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/* </Container> */}
                  <row className="row flex inline">
                    {" "}
                    <p className="lead fs-3 fw-bold mb-0 d-flex justify-content-center ">
                      Your Total:{" "}
                      <p
                        className="flex fs-3
                      lead  fw-bold mb-0"
                        style={{ color: "#f37a27" }}
                      >
                        {/* waiterintable.subtotal */}
                        {currency}&nbsp;
                        {positems.subTotal ? positems.subTotal.toFixed(2) : ""}
                        <div>
                          {/* {currency}&nbsp;{totalPrice.toFixed(2)} */}
                        </div>
                        {/* {waiterintable.waiterintable.subtotal} */}
                      </p>
                    </p>
                    <div className="col-md-4 offset-md-8 col-lg-3 offset-lg-9"></div>
                  </row>
                  {/* <p
                    className="lead fw-bold mb-4 pb-2"
                    style={{ color: "#f37a27" }}
                  >
                    Manage Orders
                  </p> */}
                  <div className="d-flex justify-content-center">
                    <ul
                      className="p-2 d-flex inline"
                      style={{
                        listStyleType: "none",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Link
                      to={`/sample-page/interface-one/${userData.orgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}`}
                      className="p-2"
                    > */}
                      <li>
                        <button
                          onClick={() => {
                            ordermore();
                            navigate(
                              `/sample-page/interface-one/${userData.orgId}/${waiterin.waiterin.id}/${waiterintable.waiterintable.id}`
                            );
                          }}
                          // color="secondary"
                          // className="m-r-10 m-t-10"
                          className="p-2 md:px-4  rounded-md flex items-center gap-2 fs-6 text-white proceed-btn"
                          // onClick={navigateToInterfaceOne}
                          style={{ minWidth: "max-content" }}
                        >
                          <i className="d-flex justify-content-center fa fa-shopping-basket me-1 "></i>
                          {"View Menu"}
                        </button>
                        {/* </Link> */}
                      </li>
                      <li>
                        <button
                          // onClick={() => openpopup()}
                          // color="success"
                          // className="m-r-10 m-t-10"
                          className=" bg-orange-500 p-2  rounded-md flex items-center fs-6 text-white"
                          // onClick={navigateToInterfaceOne}
                          onClick={() => openpopup()}
                          style={{ minWidth: "max-content" }}
                        >
                          {/* <ShoppingBag className="w-4 h-4 text-gray-700" /> */}
                          <i className="d-flex justify-content-center fa fa-print me-1 "></i>
                          {"Confirm Bill"}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="mt-4 mb-0">
                      Want any help?{" "}
                      <a href="#!" style={{ color: "#f37a27" }}>
                        Please contact us
                      </a>
                    </p>
                  </div>
                  {/* Popup for Success */}
                  {showPopupSuccess && (
                    <div className="popup-overlay">
                      <div className="popup-container">
                        <h1 className="fs-2">Bill Request Successful!</h1>
                        <p className="fs-6 pb-3"> </p>
                        <button
                          className="btnpop"
                          onClick={() => setShowPopupSuccess(false)}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Popup for Error */}
                  {showPopupError && (
                    <div className="popup-overlay">
                      <div className="popup-container">
                        <h1 className="fs-2">Bill Request Failed!</h1>
                        <p className="fs-6 pb-3">
                          An error occurred while sending the bill request.
                        </p>
                        <button
                          className="btnpop"
                          onClick={() => setShowPopupError(false)}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Popup */}{" "}
                  {showPopupconfirm && (
                    <div className="popup-overlay">
                      <div className="popup-container">
                        <h1 className="fs-4 text-warning">
                          Are You Sure, You Want To Confirm the Bill Request?
                        </h1>
                        {/* <p className="fs-6 pb-3">
                 
                </p> */}

                        <ul
                          className="p-2 d-flex inline"
                          style={{
                            listStyleType: "none",
                            // margin: 0,
                            // padding: 0,
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <li>
                            <button
                              className=" btnpop  "
                              onClick={handleno}
                              style={{ minWidth: "max-content" }}
                            >
                              Add More
                            </button>
                          </li>{" "}
                          or
                          <li>
                            <button
                              className=" btnpop "
                              onClick={handleyes}
                              style={{ minWidth: "max-content" }}
                            >
                              Confirm
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};
export default OrderDetails2;
