import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../../_helper/User";
import { saproUrlQr } from "../../../Config/Config";

const ImageBackground = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const userData = useContext(UserContext);
  const { addUserData } = useContext(UserContext);
  const navigate = useNavigate();
  let { orgId, locationId, tableId } = useParams();

  console.log(userData);

  useEffect(() => {
    const extractIdsFromUrl = () => {
      addUserData({
        saOrgId: orgId,
        locationId: locationId,
        tableId: tableId,
      });

      console.log({
        orgId: orgId,
        locationId: locationId,
        tableId: tableId,
      });

      const timeoutId = setTimeout(() => {
        navigate(
          `/sample-page/interface-one/${orgId}/${locationId}/${tableId}`
        );
      }, 2000);

      return () => clearTimeout(timeoutId);
    };

    // Call the function to trigger the execution
    extractIdsFromUrl();
  }, [orgId, locationId, tableId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://picsum.photos/800/600");
        setImageUrl(response.config.url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchData();

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const { orgId, locationId, tableId } = extractIdsFromUrl(scannedUrl);

  //   const timeoutId = setTimeout(() => {
  //     // navigate(`/sample-page/interface-one/${orgId}/${locationId}/${tableId}`);
  //     navigate(`/sample-page/interface-one/${orgId}/${locationId}/${tableId}`);
  //   }, 2000);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  useEffect(() => {
    // Store extracted parts in local storage as JSON
    const partsJson = JSON.stringify(userData.parts);
    localStorage.setItem("extractedParts", partsJson);
  }, [userData.parts]);

  const backgroundImageStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="image-background" style={backgroundImageStyle}>
      <div className="button-container">
        <button className="custom-button">View Menu</button>
      </div>
    </div>
  );
};

export default ImageBackground;
