import { Link } from "react-router-dom";
import RestaurantCard, { withTopRatedLabel } from "./RestaurantCard";
import ShimmerCard from "./ShimmerCard";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  GET_ALL_ACTIVE_CATEGORY_FOR_PO,
  GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
  defaultPath,
} from "../../utils/constants";
import { backendURL } from "../../Config/Config";
import UserProvider from "../../_helper/User/UserProvider";
import UserContext from "../../_helper/User";
import LocationContext from "../../_helper/Location";
import TableContext from "../../_helper/TableHolder";

const RestaurantList = ({ categoryId }) => {
  const RestaurantCardTopRated = withTopRatedLabel(RestaurantCard);
  const [isLoading, setIsLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurantsMenu] = useState([]);
  const [ids, setReqData] = useState({});
  const { userData } = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  let orgId = userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  console.log(orgId, locationId, tableId);

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.locationId,
    userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.locationId,
    userData.tableId,
  ]);

  console.log(ids);
  useEffect(() => {
    console.log(userData);
    console.log(userData.saOrgId);
    console.log(userData.locationId);
    console.log(userData.tableId);
    const fetchData = async () => {
      const requestData = {
        // orgId: userData.orgId || 41,
        // locationId: userData.locationId || 544,
        // tableId: userData.tableId || 694,
        orgId: orgId,
        locationId: locationId,
        tableId: tableId,
        categoryId: categoryId, // Specify the category ID you want to fetch subcategories for
      };
      console.log(requestData);
      // setReqData(requestData);
      try {
        const reqInstance = axios.create({
          baseURL: backendURL,
        });

        // Fetch categories
        const categoryResponse = await reqInstance.post(
          GET_ALL_ACTIVE_CATEGORY_FOR_PO,
          requestData
        );

        if (categoryResponse) {
          const categoryResult = categoryResponse.data;

          if (!categoryResult.hasError) {
            setRestaurants(categoryResult.categorysList); // setRestaurants is not defined, so commented out
            console.log(categoryResult);
            // Fetch subcategories for the specified category
            const subcategoryResponse = await reqInstance.post(
              GET_ALL_ACTIVE_ITEM_CATEGORY_FOR_PO,
              requestData
            );

            if (subcategoryResponse) {
              const subcategoryResult = subcategoryResponse.data;
              // console.log(subcategoryResponse);
              if (!subcategoryResult.hasError) {
                console.log(subcategoryResult);
                setFilteredRestaurantsMenu(subcategoryResult.itemsList);
                setIsLoading(false);
              } else {
                console.error(
                  "Error in subcategory API response:",
                  subcategoryResult.errorMessage
                );
                setIsLoading(false);
              }
            }
          } else {
            console.error(
              "Error in category API response:",
              categoryResult.errorMessage
            );
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orgId, locationId, tableId, categoryId]);

  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(true);
    console.log(handleShowPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="container-max pb-4">
      {/* <button
        className="my-4 mt-8 font-bold text-2xl text-zinc-700"
        onClick={() => (window.location.href = `/interface-one/restaurants`)}
      >

      </button> */}
      <div className="my-4 mt-8 font-bold text-2xl text-zinc-700">
        Discover and Get Great Food
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
        {/* Popup */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-container">
              <h1 className="fs-2">Order Placed Successfully!</h1>
              <p className="fs-6 pb-3">Your Order is being prepared. 🎉</p>
              <button className="btnpop" onClick={handleClosePopup}>
                OK
              </button>
            </div>
          </div>
        )}
        {isLoading ? (
          Array.from({ length: 30 }).map((_, i) => <ShimmerCard key={i} />)
        ) : restaurants && restaurants?.length !== 0 ? (
          restaurants.map((restaurant, i) => (
            <Link
              to={`/interface-one/restaurants/${orgId}/${locationId}/${tableId}/${restaurant.saCategoryId}`}
              className="hover:scale-95 transition ease-in-out duration-300 relative z-10 "
              key={i}
              onClick={handleShowPopup}
            >
              {restaurant.avgRating >= 4.2 ? (
                <RestaurantCardTopRated categoryList={restaurant} />
              ) : (
                <RestaurantCard categoryList={restaurant} />
              )}
            </Link>
          ))
        ) : (
          <p>No Menu Found!</p>
        )}
      </div>
    </div>
  );
};

export default RestaurantList;
