import React, { useContext, useEffect, useState } from "react";
import TableContext from "../../_helper/TableHolder";
import { clearCart, selectItemsInCart } from "../../cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import UserContext from "../../_helper/User";
import LocationContext from "../../_helper/Location";
import { ArrowLeft } from "react-feather";
import ToggleCart from "../../Components/Pages/PageLayout/ToggleCart";
import Waiter from "../../Components/Pages/PageLayout/Waiter/Waiter";

const PopupComponent = ({ matchtab }) => {
  const cartItems = useSelector(selectItemsInCart);
  const [showPopup, setShowPopup] = useState(cartItems.length > 0);
  const storedTable = localStorage.getItem("waiterintable_table");
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const navigate = useNavigate();
  const [stateValue, setStateValue] = useState();
  // const [stateValueid, setStateValue] = useState();
  const [tableStates, setTableStates] = useState({});
  const cartItem = cartItems[0];
  const tableName = cartItem && cartItem.item && cartItem.item.tableName;
  const tableId = cartItem && cartItem.item && cartItem.item.tableId;

  useEffect(() => {
    // Check if cartItems[0] and cartItems[0].item are defined before updating state
    if (cartItem && cartItem.item) {
      // Set state for a specific table ID
      setTableStates((prevStates) => ({
        ...prevStates,
        [tableName]: stateValue,
      }));
    }
  }, [stateValue, tableName, tableId]);

  // Get state for a specific table ID
  console.log("tableName:", tableName);
  console.log("tableStates:", tableStates);
  console.log(cartItems);
  console.log("cartItems[0]:", cartItems[0]);
  console.log("cartItems[0].item:", cartItems[0] && cartItems[0].item);
  console.log("tableName:", tableName);
  // console.log(cartItems[0].item?.tableId);
  console.log(waiterintable.waiterintable.id);
  console.log(waiterintable.waiterintable.table);

  const tab = JSON.parse(localStorage.getItem(`cart${cartItems.tableId}`));

  // todo
  // if table id's are same the user should able to navigate to menu and go through
  // if table mismatch on proceed the proceed can not be done
  // navigate to path exact cart by table filter

  const dispatch = useDispatch();

  const handleyes = () => {
    const saveprecart = cartItems
      .filter((item) => item.save === true && item.quantity > 0)
      .map((item) => ({
        ...item,
        tableId: cartItems.tableId,
      }));
    localStorage.setItem(
      `cart${cartItems.tableId}`,
      JSON.stringify(saveprecart)
    );
    dispatch(clearCart(true));
    setShowPopup(false);
    window.location.reload();
  };

  const handleno = () => {
    setShowPopup(false);
  };

  const handleNavigateBack = async () => {
    try {
      // await waiterin(); // Assuming waiterin is a function that returns a promise
      navigate(`/waiter/${userData.userData.saOrgId}/${waiterin.waiterin.id}`);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const gototable = () => {
    navigate(
      `/interface-one/restaurants/${userData.userData.saOrgId}/${waiterin.waiterin.id}/${tab}/cart`
    );
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div className="popup-overlay">
        <div className="popup-container">
          <h1 className="fs-4 text-warning">{`Prepare for your new order!`}</h1>
          <p className="fs-6 pb-3">
            Clear All in{" "}
            <span className="fs-5 text-warning">cart {tableName}</span> or Go
            Back to existing cart
          </p>

          <ul
            className="p-2 d-flex inline"
            style={{
              listStyleType: "none",
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <li>
              <ToggleCart />
            </li> */}
            <li className="">
              <button
                className="btnpop d-flex inline gap-2 justify-content-center"
                onClick={handleNavigateBack}
              >
                <ArrowLeft style={{ height: "15px", width: "15px" }} />
                {"Go Back"}
              </button>
            </li>
            <li>
              <button className="btnpop" onClick={handleyes}>
                {"Clear All"}
              </button>
            </li>
            {/* <li>
              <button className="btnpop" onClick={gototable}>
                {"View Cart"}
              </button>
            </li> */}
          </ul>
          {/* <ul
            className="btnpop flex gap-4 justify-content-center align-items-center"
            onClick={handleNavigateBack}
          >
            <li>
              <button>Go Back</button>
            </li>
          </ul> */}
        </div>
      </div>
    )
  );
};

export default PopupComponent;
