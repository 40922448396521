import React, { useContext, useEffect, useState } from "react";
import CartItemList from "../../../Layout/Cartdata/CartItemList";
import OrderSummary from "../../../Layout/Cartdata/OrderSummary";
import { clearCart, selectItemsInCart } from "../../../cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Btn, LI, UL } from "../../../AbstractElements";
import { Delete, Trash, Trash2 } from "react-feather";
import { Navigate } from "react-router";
import { posMasterIddefault } from "../../../utils/constants";
import { Link } from "react-router-dom";
import UserContext from "../../../_helper/User";
import LocationContext from "../../../_helper/Location";
import TableContext from "../../../_helper/TableHolder";
import PosMasterContext from "../../../_helper/PosMaster";

const Cart = () => {
  const cartItems = useSelector(selectItemsInCart);
  const dispatch = useDispatch();
  // const [orderPlaced, setOrderPlaced] = useState(false);
  // const [proceedDateTime, setProceedDateTime] = useState(null);
  console.log(cartItems);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const userData = useContext(UserContext);
  const waiterin = useContext(LocationContext);
  const waiterintable = useContext(TableContext);
  const posData = useContext(PosMasterContext);
  const [showPopup, setShowPopup] = useState(false);
  let orgId = userData.userData.saOrgId;
  const [locationId, setLocationId] = useState();
  const [tableId, setTableId] = useState();

  // Update locationId and tableId based on state values or userData if waiterin/waiterintable data is not available
  useEffect(() => {
    setLocationId(waiterin.waiterin.id ?? userData.userData.locationId);
    setTableId(waiterintable.waiterintable.id ?? userData.userData.tableId);
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  useEffect(() => {
    if (locationId == null && tableId == null) {
      setLocationId(userData.userData.locationId ?? waiterin.waiterin.id);
      setTableId(userData.userData.tableId ?? waiterintable.waiterintable.id);
    }
  }, [
    waiterin.waiterin.id,
    waiterintable.waiterintable.id,
    userData.userData.locationId,
    userData.userData.tableId,
  ]);

  console.log(orgId, locationId, tableId);
  console.log(waiterintable.waiterintable);

  // const filteredCarts = JSON.parse(
  //   localStorage.getItem(`cart${waiterintable.waiterintable.id}`)
  // );

  const handleProceed = () => {
    setOrderPlaced(true);
  };
  // const deleteItems = () => {
  //   dispatch(clearCart(true));
  // };
  const openpopup = () => {
    setShowPopup(true);
  };

  const handleyes = () => {
    dispatch(clearCart(true));
    setShowPopup(false);
    window.location.reload();
  };
  const handleno = () => {
    // dispatch(clearCart(true));
    setShowPopup(false);
  };

  return (
    <div className="container-max py-5 pb-16">
      {" "}
      <span style={{ display: "flex", alignItems: "center" }}>
        <ul
          className="p-2 d-flex inline"
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <li>
            <h1 className="text-3xl my-4 font-semibold">Cart</h1>
          </li>
          <li>
            {" "}
            <Link
              to={`/sample-page/interface-one/${orgId}/${locationId}/${tableId}`}
            >
              <Btn
                attrBtn={{
                  className: "btn btn-pill btn-air-success btn-square btn-xs",
                  color: "success",
                }}
                style={{ minWidth: "max-content" }}
              >
                {"View Menu"}
              </Btn>
            </Link>
          </li>
          <li>
            <Link to={`/order/${tableId}`}>
              <Btn
                attrBtn={{
                  className:
                    "d-flex btn btn-pill btn-air-primary btn-square btn-xs inline",
                  color: "secondary",
                }}
                style={{ minWidth: "max-content" }}
              >
                {"view order"}
              </Btn>
            </Link>
          </li>
          {cartItems && cartItems.length !== 0 && (
            <li>
              <Btn
                attrBtn={{
                  onClick: () => openpopup(),
                  className:
                    "d-flex btn btn-pill btn-air-warning btn-square btn-xs inline ",
                  color: "warning",
                }}
                style={{ minWidth: "max-content" }}
              >
                {"Clear Cart"}
              </Btn>
            </li>
          )}
        </ul>
      </span>
      {/* cart details */}
      <div className="min-h-[60vh] pb-8 md:flex gap-8">
        {/* cart items */}
        <CartItemList orderPlaced={orderPlaced} />{" "}
        {/* Pass cartItems as a prop */}
        {/* order summary */}
        {/* {cartItems && cartItems.length !== 0 ? (
          <OrderSummary cartItems={cartItems} />
        ) : null} */}
        {cartItems && cartItems.length !== 0 && (
          <OrderSummary
            onProceed={handleProceed}
            // filteredCarts={filteredCarts}
            cartItems={cartItems}
            // proceedDateTime={proceedDateTime}
          />
        )}
      </div>
      {/* 123456 test */}
      {/* Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            {waiterintable.waiterintable.table == null ? (
              <h1 className="fs-2 text-warning">
                Clear Cart for {waiterintable.waiterintable.table}
              </h1>
            ) : (
              <p className="fs-2 text-warning">Clear Cart</p>
            )}

            <p className="fs-6 pb-3">All Items in the Cart will be deleted </p>

            <ul
              className="p-2 d-flex inline"
              style={{
                listStyleType: "none",
                // margin: 0,
                // padding: 0,
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <li>
                <button className=" btnpop " onClick={handleyes}>
                  yes
                </button>
              </li>
              <li>
                <button className=" btnpop  " onClick={handleno}>
                  No
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;

// import React, { useState } from "react";
// import CartItemList from "../../../Layout/Cartdata/CartItemList";
// import OrderSummary from "../../../Layout/Cartdata/OrderSummary";
// import { selectItemsInCart } from "../../../cart/cartSlice";
// import { useSelector } from "react-redux";

// const Cart = () => {
//   const cartItems = useSelector(selectItemsInCart);

//   const handleProceed = (orderData) => {
//     console.log("Order Proceeded:", orderData);
//     // You can navigate to the OrderDetails page here or perform any other actions
//   };

//   return (
//     <div className="container-max py-5 pb-16">
//       <div className="min-h-[60vh] pb-8 md:flex gap-8">
//         <CartItemList cartItems={cartItems} /> {/* Pass cartItems as a prop */}
//         {cartItems && cartItems.length !== 0 && (
//           <OrderSummary onProceed={handleProceed} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Cart;
