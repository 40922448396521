// import React, { Fragment, useState, useEffect } from "react";
// import { Breadcrumbs, H5, P } from "../../../AbstractElements";
// import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
// import { useRef } from "react";
// import { Axios } from "axios";
// import { Image } from "react-feather";

// const SamplePage = () => {

//   const [images, setimages] = useState([]);

//   const inputRef = useRef(null);
//   const varRef = useRef(images.length);

//   return (
//     <Fragment>
//       {" "}
//       <Breadcrumbs mainTitle="Sample Card" parent="Pages" title="Sample Card" />
//       <Container fluid={true}>
//         <Row>
//           <Col sm="6">
//             <Card> {ShowImage}</Card>
//             <Card>
//               <CardHeader>
//                 <H5>Sample Card</H5>
//                 <span>
//                   lorem ipsum dolor sit amet, consectetur adipisicing elit
//                 </span>
//               </CardHeader>
//               <CardBody>
//                 <P></P>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </Fragment>
//   );
// };

// export default SamplePage;
// useEffect(() => {
//   inputRef.current.focucs();
//   Axios.get(
//     "http://api.unsplash.com.photos/?client_id=aeq02OChIQjOG2FzqRun_or3C6szSzVnvD3MuIo4dIQ"
//   ).then((res) => {
//     setimages(res.data);
//     console.log(res.data);
//   });
// }, []);

// function ShowImage() {
//   return images.map((img, index) => (
//     <Image
//       image={img.urls.regular}
//       handleRemove={handleRemove}
//       index={index}
//       key={index}
//     />
//   ));
// }

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button, Container } from "reactstrap";
import "../../../../src/assets/scss/themes/_custom.scss";
import SplashScreen from "./SplashScreen";
import ImageBackground from "./SplashScreen";

function SamplePage() {
  //   // const [bgImage, setbgImage] = useState([]);

  //   // useEffect(() => {
  //   //   Axios.get("https://picsum.photos/v2/list?page=1&limit=1").then((res) => {
  //   //     setbgImage(res.data);
  //   //   });
  //   // }, []);

  return (
    <div>
      <ImageBackground />
      {/* <div>
        {bgImage.map((image, index) => (
          <img
            key={index}
            src={image.download_url}
            alt={`Background Image ${index}`}
            style={{
              width: "100%",
              height: "100%",
              //display: "none",
            }}
          />
        ))}

        <Button className="button">Open Menu</Button>
      </div> */}
    </div>
  );
}

export default SamplePage;
